import React, {useEffect} from "react";
import "../css/mobile.css";
import  $ from "jquery";
import MobileSlider from "./mSlider";
import mobile_banner from "../file/Mobile Banner Advertisement.mp4";
import MobileFooter from "./mFooter";
import MobileHeader from "./mHeader";
import {useNavigate} from "react-router-dom";
import MobileFooterNoticeSlider from "./mFooterNoticeSlider";
import axios from "axios";
import baseUrl from "../BaseUrl";

const MobileIndex = () => {
    const navigate = useNavigate();

    const verifyToken = async () => {
        if(localStorage.getItem('token')){
            try {
             await axios.get(baseUrl + 'verify_token/',{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });
            } catch {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            }
        }
    };

    function openGamePage(gameName) {
        localStorage.setItem('gameName', gameName);
        window.location.href = '/mobile/upcoming_games/?gameName='+gameName;
    }

    const openNotice = () => {
      navigate('/service_center/notice');
      window.location.reload();
    };


    const pcGames = (gameName) => {
        if(localStorage.getItem('token'))
            window.location.href = `/game?gameName=${gameName}`;
        else
            navigate('/mobile/login', {state:{redirect: `/game?gameName=${gameName}`}});
    };


    const mobileGames = (gameName) => {
        const isIphone = /iPhone/i.test(navigator.userAgent);
        const Android = /Android/i.test(navigator.userAgent);

        if(isIphone || Android){
            if(gameName === "sm_dualgo") {
                if(isIphone) {
                    // replace this below function with android game url
                    // example window.location.href = "android game url"

                    pcGames(gameName);
                } else {
                    window.location.href = "https://play.google.com/store/apps/details?id=kr.co.smgame.dualgo1";
                }
            }
            else if(gameName === "sm_baduki"){
                if(isIphone) {
                    // replace this below function with iPhone game url
                    // example window.location.href = "iPhone game url"

                    pcGames(gameName);
                } else {
                    // replace this below function with android game url
                    // example window.location.href = "android game url"

                    pcGames(gameName);
                }
            }
            else if(gameName === "sm_poker"){
                if(isIphone) {
                    // replace this below function with iPhone game url
                    // example window.location.href = "iPhone game url"

                    pcGames(gameName);
                } else {
                    // replace this below function with android game url
                    // example window.location.href = "android game url"

                    pcGames(gameName);
                }
            }
            else if(gameName === "sm_smpl"){
                if(isIphone) {
                    // replace this below function with iPhone game url
                    // example window.location.href = "iPhone game url"

                    pcGames(gameName);
                } else {
                    // replace this below function with android game url
                    // example window.location.href = "android game url"

                    pcGames(gameName);
                }
            }

        } else {
            pcGames(gameName);
        }

    };


    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'background-color': '#333333',
            'zoom': 'unset',
        });

        verifyToken();

        localStorage.setItem('isMobile', true);
    }, []);


    return (
        <div style={{backgroundColor: '#f5f5f5'}}>
            <MobileHeader/>
            <MobileSlider/>

            <div className="m_game_content">
                <div className="m_section_tit_box_inner">
                    <span style={{borderBottom: "0.6250vw solid #15a3e1"}}>주</span>
                    <span style={{borderBottom: "0.6250vw solid #4e4e4e"}}>요게임</span>
                </div>

                <div className="m_recommended_game_box">
                    <div className="m_recommended_game_img m_img_dualgo" onClick={() => mobileGames('sm_dualgo')} />
                    <div className="m_recommended_game_txt">
                        <div style={{color: '#3f3f3f', fontSize: '4.6875vw'}}>SM신맞고</div>
                        <div>칠수록 재미가 따닥!</div>

                        <div className="m_play_game">
                            <span>게임하기</span>
                            <div className="m_play_game_img"/>
                            <div className="m_play_store_img_2" onClick={() => mobileGames('sm_dualgo')}/>
                            <div className="m_play_store_img_3" onClick={() => mobileGames('sm_dualgo')}/>
                        </div>
                    </div>
                </div>

                <div className="m_recommended_game_box">
                    <div className="m_recommended_game_img m_img_baduki" onClick={() => mobileGames('sm_baduki')}/>
                    <div className="m_recommended_game_txt">
                        <div style={{color: '#3f3f3f', fontSize: '4.6875vw'}}>SM바둑이</div>
                        <div>더욱 짜릿하고 강력해지다</div>

                        <div className="m_play_game">
                            <span>게임하기</span>
                            <div className="m_play_game_img"/>
                            <div className="m_play_store_img_2" onClick={() => mobileGames('sm_baduki')}/>
                            <div className="m_play_store_img_3" onClick={() => mobileGames('sm_baduki')}/>
                        </div>
                    </div>
                </div>

                <div className="m_recommended_game_box">
                    <div className="m_recommended_game_img m_img_poker" onClick={() => mobileGames("sm_poker")}></div>
                    <div className="m_recommended_game_txt">
                        <div style={{color: '#3f3f3f', fontSize: '4.6875vw'}}>SM7포커</div>
                        <div>눈을 뗄수 없는 긴장감</div>

                        <div className="m_play_game">
                            <span>게임하기</span>
                            <div className="m_play_game_img"/>
                            <div className="m_play_store_img_2" onClick={() => mobileGames("sm_poker")}/>
                            <div className="m_play_store_img_3" onClick={() => mobileGames("sm_poker")}/>
                        </div>
                    </div>
                </div>

                <div className="m_recommended_game_box">
                    <div className="m_recommended_game_img m_img_smpl" onClick={() => mobileGames("sm_smpl")}></div>
                    <div className="m_recommended_game_txt">
                        <div style={{color: '#3f3f3f', fontSize: '4.6875vw'}}>텍사스홀덤(SMPL)</div>
                        <div>최고의 카드 심리전</div>

                        <div className="m_play_game">
                            <span>게임하기</span>
                            <div className="m_play_game_img"/>
                            <div className="m_play_store_img_2" onClick={() => mobileGames("sm_smpl")}/>
                            <div className="m_play_store_img_3" onClick={() => mobileGames("sm_smpl")}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="m_fbanner">
                <video src={mobile_banner} autoBuffer playsInline loop autoPlay muted height="100%" width="100%">
                </video>
            </div>

            <div className="game_box_contents">
                <div className="m_game_content">
                    <div className="m_section_tit_box_inner">
                        <span style={{borderBottom: "0.6250vw solid #15a3e1"}}>언</span>
                        <span style={{borderBottom: "0.6250vw solid #4e4e4e"}}>박싱 게임 Coming Soon!</span>
                    </div>
                </div>

                <div className="m_game_box">
                    <div className="m_game_img_box" onClick={event => openGamePage('game_janggi')}>
                        <div className="m_game_box_img_div m_game_box_img_janggi"/>
                    </div>
                    <div className="m_game_title_text">장기</div>
                    <div className="m_game_explain_text">전통 보드 게임을 웹에서 즐겨라!</div>
                </div>

                <div className="m_game_box">
                    <div className="m_game_img_box" onClick={event => openGamePage('game_matching')}>
                        <div className="m_game_box_img_div m_game_box_img_matching"/>
                    </div>
                    <div className="m_game_title_text">SM MATCHING</div>
                    <div className="m_game_explain_text">정확한 타이밍을 맞춰라</div>
                </div>

                <div className="m_game_box">
                    <div className="m_game_img_box" onClick={event => openGamePage('game_sudda')}>
                        <div className="m_game_box_img_div m_game_box_img_sudda"/>
                    </div>
                    <div className="m_game_title_text">섯다</div>
                    <div className="m_game_explain_text">치열한 두뇌싸움의 극!</div>
                </div>

                <div className="m_game_box">
                    <div className="m_game_img_box" onClick={event => openGamePage('game_gostop')}>
                        <div className="m_game_box_img_div m_game_box_img_gostop"/>
                    </div>
                    <div className="m_game_title_text">고스톱</div>
                    <div className="m_game_explain_text">꽃들의 싸움!</div>
                </div>

                <div className="m_game_box">
                    <div className="m_game_img_box" onClick={event => openGamePage('game_battlesniper')}>
                        <div className="m_game_box_img_div m_game_box_img_battlesniper"/>
                    </div>
                    <div className="m_game_title_text">BATTLE SNIPER</div>
                    <div className="m_game_explain_text">적의 심장을 노려라</div>
                </div>

                <div className="m_game_box">
                    <div className="m_game_img_box" onClick={event => openGamePage('game_probaseball')}>
                        <div className="m_game_box_img_div m_game_box_img_probaseball"/>
                    </div>
                    <div className="m_game_title_text">프로 베이스볼</div>
                    <div className="m_game_explain_text">나만의 구단을 육성하다!</div>
                </div>
            </div>


            <div className="m_notice_box">
                <div className="m_notice_box_content">
                    <span onClick={() => openNotice()} style={{fontSize: '3.7500vw', color: '#4e4e4e'}}>
                        공지사항
                    </span>

                    <MobileFooterNoticeSlider/>
                </div>
            </div>

            <MobileFooter/>

        </div>
    );
}

export default MobileIndex;