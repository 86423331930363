import React, { useState, useEffect } from 'react';
import $ from "jquery";

import blue_girl from '../images/main_img/blue_girl.png';
import Rectangle from '../images/main_img/Rectangle 12.png';

import banner_gostop from '../images/Banner/gostop.png';
import banner_suddo from '../images/Banner/sudda.png';
import banner_darkworld from '../images/Banner/darkworld.png';
import banner_battlesniper from '../images/Banner/battlesniper.png';
import banner_probaseball from '../images/Banner/probaseball.png';
import banner_baduk from '../images/Banner/baduk.png';
import banner_janggi from '../images/Banner/janggi.png';
import banner_matching from '../images/Banner/matching3.png';


import G_gostop from '../images/main_img/G-gostop.jpg';
import G_sudda from '../images/main_img/G-sudda.jpg';
import G_darkworld from '../images/main_img/G-darkworld.jpg';
import G_battlesniper from '../images/main_img/G-battlesniper.jpg';
import G_probaseball from '../images/main_img/G-probaseball.jpg';
import G_baduk from '../images/main_img/G-baduk.jpg';
import G_janggi from '../images/main_img/G-janggi.jpg';
import G_matching from '../images/main_img/G-machine.png';

function GameButton({ index_number, gameName, onClick, imageSrc}) {
    return (
        // onMouseEnter={() => onClick(index_number, true)} onMouseLeave={() => onClick(index_number, false)}
        <button onClick={() => onClick(index_number)}>
            <div className="card_item col_type1">
                <img loading="lazy" id={gameName} className="image-shadow" src={imageSrc} alt={gameName}  style={{ opacity: gameName === 'game_gostop' ? 1 : 0.3 }}/>
            </div>
        </button>
    );
}

function GameMenu({ games, onClickGame, seeMoreHref, bannerSlideStatus }) {
    return (
        <>
        <div className="game_Menu_title">
            <div id="tab1" className="tab_content">
                {games.slice(0, 4).map((game, index) => (
                    <GameButton
                        key={index}
                        index_number={game.index}
                        gameName={game.name}
                        onClick={onClickGame}
                        imageSrc={game.image_src}
                        details={game.details}
                    />
                ))}
                <div id="game_detail" className="game_details">
                    SM 고스톱은 PC와 모바일에서 여러 플레이어들과 함께 즐길 수 있는 게임입니다. 짝짝 붙는 패의 손맛과 시선을 사로잡는 이펙트로 오늘도 즐거움 Up! Up!
                </div>
            </div>
        </div>

        <div className="game_Menu_title">
            <div id="tab1" className="tab_content">
                {games.slice(4, 8).map((game, index) => (
                    <GameButton
                        key={index}
                        index_number={game.index}
                        gameName={game.name}
                        onClick={onClickGame}
                        imageSrc={game.image_src}
                        details={game.details}
                    />
                ))}

                <div className="see_more" id="see_more" onClick={seeMoreHref}
                     onMouseEnter={() => bannerSlideStatus(true)} onMouseLeave={() => bannerSlideStatus(false)}
                     style={{marginLeft: '15rem', cursor: 'pointer', marginTop: '1rem'}}>

                    <button className="btn bttn bttn-primary">
                        <div className="see_more_btn">더보기 <div className="see_more_btn_arrow"/>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        </>
    );
}


function GameBannerContainer() {
    const [currentGame, setCurrentGame] = useState(0);
    const [currentGameStop, setCurrentGameStop] = useState(false);
    const [gameList] = useState([
        { index: 0, name: 'game_gostop', image_src: G_gostop, banner: banner_gostop, url: '', details: "SM 고스톱은 PC와 모바일에서 여러 플레이어들과 함께 즐길 수 있는 게임입니다. 짝짝 붙는 패의 손맛과 시선을 사로잡는 이펙트로 오늘도 즐거움 Up! Up!" },
        { index: 1, name: 'game_sudda', image_src: G_sudda, banner: banner_suddo, url: '', details: "SM 섯다는 16:9 해상도를 지원하는 고퀄리티의 섯다 게임입니다. 어느 플랫폼에서나 쉽고 간편하게 즐길 수 있으며, 짜릿한 족보 완성의 재미와 긴장감 넘치는 베팅 심리전의 재미를 느끼실 수 있습니다." },
        { index: 2, name: 'game_darkworld', image_src: G_darkworld, banner: banner_darkworld, url: '', details: "Dark World는 온갖 종족이 존재하는 세계에서 세력의 패권을 두고 전쟁을 벌이는 MMORPG입니다."},
        { index: 3, name: 'game_battlesniper', image_src: G_battlesniper, banner: banner_battlesniper, url: '', details: "Battle Sniper는 극한의 생존 전략을 토대로 즐기는 FPS 게임입니다.주변의 물건을 이용해 자신을 보호하고 적을 제거하여 최후의 1인이 되세요! "},
        { index: 4, name: 'game_probaseball', image_src: G_probaseball, banner: banner_probaseball, url: '', details:  "SM 프로 베이스볼은 PC에서 경기를 진행하거나 Mobile에서 간소화된 경기를 확인하는 등의 상호 보완적인 관계를 통해 끊임없이 구단을 운영하며, 선수들을 육성해 나만의 구단을 만들 수 있습니다."},
        { index: 5, name: 'game_baduk', image_src: G_baduk, banner: banner_baduk, url: '', details: "SM 바둑은 Cross-Platform 기술을 활용하여 사용자가 언제 어디서나 편안한 환경에서 즐길 수 있는 바둑 게임입니다."},
        { index: 6, name: 'game_janggi', image_src: G_janggi, banner: banner_janggi, url: '', details: "SM 장기는 Cross-Platform 기능을 통해 어디에서나 게임을 즐길 수 있는 장기 게임입니다. 전통적이면서도 클래식한 디자인과 룰로 개인의 감성을 충분히 표현할 수 있는 캐주얼함까지 갖추고 있습니다."},
        { index: 7, name: 'game_matching', image_src: G_matching, banner: banner_matching, url: '', details: "SM matching은 떨어지는 그림들의 짝을 맞추는 게임이며, 타이밍에 맞게 본인이 버튼을 눌러 그림을 맞출 수 있습니다."},
        // Add other games here
    ]);


    function openGamePage(){
        const gameName  = gameList[currentGame].name
        window.location.href = '/upcoming_games/?gameName='+gameName;
    }


    function Banner({ gameName, bannerSrc, gameUrl, details }) {
        $("#game_detail").text(details);
        $(".col_type1 img").css("opacity", "0.3");
        $("#" + gameName).css("opacity", "1");

        return (
            <div id="main_banner_page" onClick={openGamePage} style={{cursor: 'pointer'}}>
                <img loading="lazy" id="main_banner" style={{ width: '51.3125rem', height: '15.0625rem', marginTop: '20px', flexShrink: 0, borderRadius: '30px', transition: 'ease-in-out' }} src={bannerSrc} alt="Banner" />
            </div>
        );
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
            if(!currentGameStop){
                setCurrentGame(current => (current + 1) % gameList.length);
            }
        }, 4000);
        return () => clearInterval(intervalId);
    }, [currentGameStop]);


    const changeGameBanner = (index_number, stop) => {
        $("#game_detail").text(gameList[index_number].details);
        $(".col_type1 img").css("opacity", "0.3");
        $("#" + gameList[index_number].name).css("opacity", "1");
        $("#main_banner").attr("src", gameList[index_number].banner);
        setCurrentGame(index_number);
        if(stop) {
            setCurrentGameStop(true);
        } else {
            setCurrentGameStop(false);
        }
    };


    return (
        <div className="menu_right" style={{ width: '70%' }}>
            <div className="menu_title_text">
                <span className="tabtext">언박싱 게임 Coming Soon!</span>
                <img loading="lazy" src={Rectangle} alt="Coming Soon" />
            </div>
            <Banner gameName={gameList[currentGame].name} onClick={changeGameBanner} bannerSrc={gameList[currentGame].banner} details={gameList[currentGame].details} />

            <div className="games_opt_list">
                <GameMenu games={gameList} onClickGame={changeGameBanner} seeMoreHref={openGamePage} bannerSlideStatus={setCurrentGameStop}/>
            </div>
        </div>
    );
}

function GameBanner() {
    return (
        <div id="contents" className="upcoming_game_contents">
            <div className="center_container">
                <div className="menu_left" style={{ width: '35%', textAlign: 'center' }}>
                    <img loading="lazy" src={blue_girl} style={{ width: '26rem', height: '40rem', paddingRight: '30px', marginTop: '14px' }} alt="Blue Girl" />
                </div>
                <GameBannerContainer />
            </div>
        </div>
    );
}

export default GameBanner;
