import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';

import '../css/main.css';
import GoStap from '../file/main_banner/Gostop.mp4';
import Baduki from '../file/main_banner/Baduki.mp4';
import Poker from '../file/main_banner/7 Poker.mp4';
import SMPL from '../file/main_banner/SMPL.mov';

import React, {useRef, useState} from "react";
import {A11y, Autoplay, Navigation} from "swiper/modules";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import baseUrl from "../BaseUrl";

function Slider() {
    const navigate = useNavigate();
    const swiperRef = useRef(null);

    const [nextClass, setNextClass] = useState('SliderButton');
    const [prevClass, setPrevClass] = useState('SliderButtonRound');

    const [backgroundColor, setBackgroundColor] = useState('unset');

    const handleSlideChange = (swiper) => {
        const activeSlide = swiper.slides[swiper.activeIndex];
        const video = activeSlide.querySelector('video');
        if (video) {
          video.currentTime = 0;
          video.play();
        }
      };

    const goToNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();

            setNextClass('SliderButtonRound');
            setPrevClass('SliderButton');

        }
    };

    const goToPrevSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();

            setNextClass('SliderButton');
            setPrevClass('SliderButtonRound');

        }
    };

    const openGamePage = async (gameName) => {
        try {
            const response = await axios.get(baseUrl + 'verify_token', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                navigate(`/game?gameName=${gameName}`);
            }

        } catch (e) {
            window.scroll(0, 0);
            navigate('/login', {state: {redirect: `/game?gameName=${gameName}`}});
        }
    };

    return (
        <div className="rolling_img">
            <div className="swiper1">
                <Swiper
                    modules={[Autoplay, Navigation, A11y]}
                    spaceBetween={1}
                    slidesPerView={1}
                    navigation={true}
                    ref={swiperRef}
                    loop={true}
                    speed={1500}
                    autoplay={{delay: 10000, disableOnInteraction: false}}
                    onSlideChange={handleSlideChange}
                    >

                        <div className="swiper1">
                            <SwiperSlide key={0}>
                                <div style={{backgroundColor: backgroundColor}} className="swiper-slide" onClick={event => openGamePage('sm_dualgo')}>
                                    <video src={GoStap} autoBuffer playsInline autoPlay muted onLoadedData={() => setBackgroundColor('#cf272d')}></video>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide key={1}>
                                <div style={{backgroundColor: '#010102'}} className="swiper-slide" onClick={event => openGamePage('sm_baduki')}>
                                    <video src={Baduki} autoBuffer playsInline autoPlay muted></video>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide key={2}>
                                <div style={{backgroundColor: '#000000'}} className="swiper-slide" onClick={event => openGamePage('sm_poker')}>
                                    <video src={Poker} autoBuffer playsInline autoPlay muted></video>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide key={3}>
                                <div style={{backgroundColor: '#010304'}} className="swiper-slide" onClick={event => openGamePage('sm_smpl')}>
                                    <video src={SMPL} autoBuffer playsInline autoPlay muted></video>
                                </div>
                            </SwiperSlide>

                        </div>


                    </Swiper>
            </div>


            <div className="SliderButtonDiv">
                <button onClick={goToPrevSlide} className={nextClass}></button>
                <button onClick={goToNextSlide} className={prevClass}></button>
            </div>

        </div>
    );

}

export default Slider;