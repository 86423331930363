import logo from "../images/SM Logo 1.svg";
import React from "react";
import {useNavigate} from "react-router-dom";


function SubHeader() {
    const navigate = useNavigate();

    return (
        <div id="Topwrap" className="header-wrap">
          <div id="headerclass" className="header login-header">
              <div id="center_navigation1">
                    <button>
                        <img className="log-img" src={logo} onClick={() => navigate('/')}  alt=""/>
                    </button>

                    <div id="header_left" className="header_menu_opt login-header_menu_opt">
                    </div>
              </div>
          </div>
        </div>
    );

}

export default SubHeader;
