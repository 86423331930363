import React, {useRef, useEffect} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import $ from 'jquery';
import {A11y, Autoplay, Navigation} from "swiper/modules";
import {useNavigate} from "react-router-dom";
import '../css/main.css';

function MobileSlider() {
    const navigate = useNavigate();
    const swiperRef = useRef(null);

    const slideTo = (event, index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
            swiperRef.current.swiper.autoplay.start();

            $('.m_active_bullet').removeClass('m_active_bullet');
            $(event.target).addClass('m_active_bullet');
        }
    };

    useEffect(() => {
        const swiperInstance = swiperRef.current.swiper;

        const updateActiveBullet = () => {
            const activeIndex = swiperInstance.realIndex;
            const bullets = document.querySelectorAll('.m_swiper_pagination_bullet');

            bullets.forEach((bullet, index) => {
                if (index === activeIndex) {
                    bullet.classList.add('m_active_bullet');
                } else {
                    bullet.classList.remove('m_active_bullet');
                }
            });
        };

        swiperInstance.on('slideChange', updateActiveBullet);

        // Clean up the event listener on component unmount
        return () => {
            swiperInstance.off('slideChange', updateActiveBullet);
        };
    }, []);

    const pcGames = (gameName) => {
        if(localStorage.getItem('token'))
            window.location.href = `/game?gameName=${gameName}`;
        else
            navigate('/mobile/login', {state:{redirect: `/game?gameName=${gameName}`}});
    };

    const mobileGames = (gameName) => {
        const isIphone = /iPhone/i.test(navigator.userAgent);
        const Android = /Android/i.test(navigator.userAgent);

        if(isIphone || Android){
            if(gameName === "sm_dualgo") {
                if(isIphone) {
                    // replace this below function with android game url
                    // example window.location.href = "android game url"

                    pcGames(gameName);
                } else {
                    window.location.href = "https://play.google.com/store/apps/details?id=kr.co.smgame.dualgo1";

                }
            }
            else if(gameName === "sm_baduki"){
                if(isIphone) {
                    // replace this below function with iPhone game url
                    // example window.location.href = "iPhone game url"

                    pcGames(gameName);
                } else {
                    // replace this below function with android game url
                    // example window.location.href = "android game url"

                    pcGames(gameName);
                }
            }
            else if(gameName === "sm_poker"){
                if(isIphone) {
                    // replace this below function with iPhone game url
                    // example window.location.href = "iPhone game url"

                    pcGames(gameName);
                } else {
                    // replace this below function with android game url
                    // example window.location.href = "android game url"

                    pcGames(gameName);
                }
            }
            else if(gameName === "sm_smpl"){
                if(isIphone) {
                    // replace this below function with iPhone game url
                    // example window.location.href = "iPhone game url"

                    pcGames(gameName);
                } else {
                    // replace this below function with android game url
                    // example window.location.href = "android game url"

                    pcGames(gameName);
                }
            }

        } else {
            pcGames(gameName);
        }

    };


    return (
        <div>
            <div className="swiper1 mobile_swiper">
                <Swiper
                    modules={[Autoplay, Navigation, A11y]}
                    spaceBetween={1}
                    slidesPerView={1}
                    ref={swiperRef}
                    loop={true}
                    speed={1500}
                    autoplay={{ delay: 1500, disableOnInteraction: false }}
                >
                    <SwiperSlide key={0}>
                        <div className="m_dualgo_banner" onClick={() => mobileGames('sm_dualgo')}/>
                    </SwiperSlide>
                    <SwiperSlide key={1}>
                        <div className="m_baduki_banner" onClick={() => mobileGames("sm_baduki")}/>
                    </SwiperSlide>
                    <SwiperSlide key={2}>
                        <div className="m_poker_banner" onClick={() => mobileGames("sm_poker")}/>
                    </SwiperSlide>
                    <SwiperSlide key={3}>
                        <div className="m_smpl_banner" onClick={() => mobileGames("sm_smpl")}/>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div className="m_swiper_pagination">
                <div className="m_swiper_pagination_bullet m_active_bullet" onClick={(event) => slideTo(event, 0)}/>
                <div className="m_swiper_pagination_bullet" onClick={(event) => slideTo(event, 1)}/>
                <div className="m_swiper_pagination_bullet" onClick={(event) => slideTo(event, 2)}/>
                <div className="m_swiper_pagination_bullet" onClick={(event) => slideTo(event, 3)}/>
            </div>
        </div>
    );
}

export default MobileSlider;
