import React, {useEffect, useState} from 'react';
import '../css/webPopup.css';
import $ from "jquery";
import gold_icon from "../images/dualgo_ingame_img/ingame_activeGO.png";
import egg_icon from "../images/dualgo_ingame_img/ingame_activeAL.png";
import divider_icon from "../images/dualgo_ingame_img/linePart.svg";
import left_arrow from "../images/dualgo_ingame_img/Arrow_left.svg";
import right_arrow from "../images/dualgo_ingame_img/Arrow_right.svg";
import axios from "axios";
import baseUrl from "../BaseUrl";
import ToKoreanNotation from "../popups/popupComman";
import MessagePopup, {CheckPlatform} from "./messagePopup";
import CashBox from "../css/inGamePopup.module.css";


const InGameCashBox = () => {
    const [currentTab, setCurrentTab] = useState('payIn');
    const [currency, setCurrency] = useState('go');
    const [inputMoney, setInputMoney] = useState('');
    const [submitMoney, setSubmitMoney] = useState('');
    const [offset, setOffset] = useState(0);
    const [historyCurrentIndex, setHistoryCurrentIndex] = useState(0);
    const [unitDisplayGO, setUnitDisplayGO] = useState('');
    const [inputLabel, setInputLabel] = useState('입금액');
    const [currencyUnit, setCurrencyUnit] = useState('');
    const [moneyData, setMoneyData] = useState({go: {}, al: {}});
    const [historyData, setHistoryData] = useState([{}]);
    const [submitBtnTxt, setSubmitBtnTxt] = useState('입금하기');
    const [dataLoad, setDataLoad] = useState(false);
    const [platformMessage, setPlatformMessage] = useState('');
    const [showCloseBtn, setShowCloseBtn] = useState(false);

    const [messagePopup, setMessagePopup] = useState(false);
    const [status, setStatus] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const gameName = params.get('gameName');
    const token = params.get('token');


    const historyGameName = {
        sm_dualgo: "SM 신맞고",
        sm_poker: "SM 7포커",
        sm_baduki: "SM 바둑이",
        sm_smpl: "SMPL"
    };

    // const closeWindow = () => {
    //     window.close()
    // };

    const changeCurrency = (currency_type) => {
        setCurrency(currency_type);
        setOffset(0);
        setHistoryCurrentIndex(0);
        setHistoryData([{}]);
        changeTableMenu('payIn');

        if (currency_type === 'go') {
            setCurrencyUnit(unitDisplayGO);
        } else {
            setCurrencyUnit('알');
        }

    };

    const changeTableMenu = (tab) => {
        setCurrentTab(tab);
        if (tab === "payIn") {
            setInputLabel("입금액");
            setSubmitBtnTxt('입금하기');
        } else if (tab === "payOut") {
            setInputLabel("출금액");
            setSubmitBtnTxt('출금하기');
        } else {
            setOffset(0);
            fetchCashBoxHistory(0, "table_change");
        }
        setInputMoney('');
    };


    const handleInputChange = (value) => {
        var money = parseInt(value.replace(/([만억조])/gi, '').replace(/,/g, ''));

        if (money < 0 || isNaN(money)) {
            setInputMoney('');
            return;
        }


        if (currentTab === "payIn"){
            var game_money = ToKoreanNotation(moneyData[currency].game_money);
            game_money = parseInt(game_money.replace(/([만억조])/gi, '').replace(/,/g, ''));

            if (money >= game_money){
                setInputMoney(ToKoreanNotation(moneyData[currency].game_money, ""));
                setSubmitMoney(moneyData[currency].game_money);
                return;
            }
        } else {
            var safe_money = ToKoreanNotation(moneyData[currency].safe_money);
            safe_money = parseInt(safe_money.replace(/([만억조])/gi, '').replace(/,/g, ''));


            if (money >= safe_money ){
                setInputMoney(ToKoreanNotation(moneyData[currency].safe_money, ""));
                setSubmitMoney(moneyData[currency].safe_money);
                return;
            }
        }

        inputToKoreanNotation(money);

    };

    function inputToKoreanNotation(money) {
		money = money.toString();
        var str1, str2, str3, str4, sum;

		if (money.length <= 4) {
			sum = money;
		} else if (money.length > 4 && money.length < 9) {
			//만
			str1 = money.slice(-4);
			str2 = money.slice(-money.length, -4);
			sum = str2 + '만' + str1;

		} else if (money.length > 8 && money.length < 13) {
			//억
			str1 = money.slice(-4);
			str2 = money.slice(-8, -4);
			str3 = money.slice(-money.length, -8);
			sum = str3 + '억' + str2 + '만' + str1;

		} else if (money.length > 12 && money.length < 17) {
			//조
			str1 = money.slice(-4);
			str2 = money.slice(-8, -4);
			str3 = money.slice(-12, -8);
			str4 = money.slice(-money.length, -12);
			sum = str4 + '조' + str3 + '억' + str2 + '만' + str1;

		}

        setInputMoney(sum);
        setSubmitMoney(money);
	}


    const fetchMoneyData = async () => {
        try {
            const response = await axios.get(baseUrl + 'popups/cash_box/', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200){
                setMoneyData(response.data);
            }

        } catch (e) {
            alert("Invalid token");
        }
    };


    const fetchCashBoxHistory = async (page_offset, table_change=null) => {
        try{
            const response = await axios.post(baseUrl + 'popups/cash_box_history/', {
                    currency: currency,
                    offset: page_offset
                },

                {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200){
                setDataLoad(true);

                if(response.data.length > 0) {
                    if (table_change){
                        setHistoryCurrentIndex( 0);
                        setHistoryData(response.data);
                    }
                    else if (historyData.length === 1){
                        setHistoryData(response.data);
                    } else {
                        const newArray = historyData.concat(response.data);
                        setHistoryData(newArray);
                        setHistoryCurrentIndex(historyCurrentIndex + 1);
                    }
                }
            }

        } catch (e) {

        }
    };


    async function handleOnSubmit() {

        if (inputMoney < 1 || !inputMoney) {
            setStatus(false);

            if (currentTab === "payIn")
                setMessagePopup("입금하실 금액을 입력해 주세요.");
            else
                setMessagePopup("출금하실 금액을 입력해 주세요.");

            $('.message-main-pop').css("display", "block");
            return ;
        }

        const data = {
            currency: currency,
            amount: submitMoney,
            game_name: gameName,
            category: currentTab
        };

        setInputMoney('');

        try {
            const response = await axios.post(baseUrl + 'popups/update_cash_box_money/', data, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200) {
                setStatus(true);
                if (currentTab === "payIn"){
                    setMessagePopup("머니 입금이 완료되었습니다.");
                } else {
                    setMessagePopup("머니 출금이 완료되었습니다.");
                }

                $('.message-main-pop').css("display", "block");
                setMoneyData(response.data);
            } else if(response.status === 204) {
                setStatus(false);
                setMessagePopup("게임 진행 중에는 금고 입•출금이 제한 됩니다.");
                $('.message-main-pop').css("display", "block");
            }

        } catch (e) {
            console.log(e);
            setStatus(false);
            setMessagePopup("Internal Server Error");
            $('.message-main-pop').css("display", "block");
        }


    }

    function onPrevClick() {
        if (historyCurrentIndex >= 1) {
            setHistoryCurrentIndex(historyCurrentIndex - 1);
        } else {
            setStatus(false);
            setMessagePopup("입출금 내역이 없습니다.");
            $('.message-main-pop').css("display", "block");
        }
    }

    function onNextClick() {
        if (historyCurrentIndex <= 49){
            if ( historyCurrentIndex + 1  === historyData.length){
                if (historyData.length === (10 + offset)){
                    setOffset(offset + 10);
                    setDataLoad(false);
                    fetchCashBoxHistory(offset + 10);
                } else if (dataLoad) {
                    setStatus(false);
                    setMessagePopup("최초의 입출금 내역입니다.");
                    $('.message-main-pop').css("display", "block");
                }
            } else {
                setHistoryCurrentIndex(historyCurrentIndex + 1);
            }
        } else {
            setStatus(false);
            setMessagePopup("내역은 최대 50개까지만 보여집니다.");
            $('.message-main-pop').css("display", "block");
        }
    }

    const closeWindow = () => {
        window.self.close();
    };


    useEffect(() => {
         $('body').css({
            'transform': 'unset',
            'min-width': 'unset',
             'zoom': 'unset',
        });

         if (!gameName) {
            return alert("gameName is missing in url parameter");
        } else if (!token){
            return alert("token is missing in url parameter");
        }

        fetchMoneyData();

        if (gameName === "sm_dualgo") {
            setUnitDisplayGO("냥");
            setCurrencyUnit("냥");
        } else {
            setUnitDisplayGO("골드");
            setCurrencyUnit("골드");
        }

        var checkPlatform = CheckPlatform();
        setPlatformMessage(checkPlatform[0]);
        setShowCloseBtn(checkPlatform[1]);

    }, [gameName]);


    return (
        <>
            {messagePopup && (
                <MessagePopup message={messagePopup} status={status} closeMessagePopup={setMessagePopup}/>)}

            <div id="include_page" className={CashBox.in_game_popup_background}>
                {showCloseBtn && (<div className={CashBox.in_game_close_btn} onClick={closeWindow}/>)}


                <div className="">
                    <div className={CashBox.cash_box_currency_switch}>
                        <div
                            onClick={() => changeCurrency('go')}
                            className={`${CashBox.currency_option} ${currency === 'go' ? CashBox.currency_option_active : CashBox.currency_option_not_active}`}>
                            <img src={gold_icon} alt="currency-symbol-1" style={{width: '2vw', height: '2vw'}}/>
                            <p style={{
                                color: currency === 'go' ? '#fff' : '#e4831b',
                                fontSize: '1.6vw',
                                fontWeight: 700
                            }}>{unitDisplayGO}</p>
                        </div>
                        <div
                            onClick={() => changeCurrency('al')}
                            className={`${CashBox.currency_option} ${currency === 'al' ? CashBox.currency_option_active : CashBox.currency_option_not_active}`}>

                            <img src={egg_icon} alt="currency-symbol-2" style={{width: '1.8vw', height: '1.8vw'}}/>
                            <p style={{
                                color: currency === 'al' ? '#fff' : '#e4831b',
                                fontSize: '1.6vw',
                                fontWeight: 700
                            }}>알</p>
                        </div>
                    </div>

                    <div className={CashBox.cash_box_contents_inner_box}>
                        <div className={CashBox.cash_box_contents_tab_inner_box}>
                            <div
                                id="tab1T"
                                className={`${CashBox.cash_box_tab} ${currentTab === 'payIn' ? CashBox.active : CashBox.inactive}`}
                                onClick={() => changeTableMenu('payIn')}
                                style={{borderRight: 0, borderRadius: '0.7vw 0 0 0.7vw'}}
                            >
                                머니입금
                            </div>

                            <div
                                id="tab2T"
                                className={`${CashBox.cash_box_tab} ${currentTab === 'payOut' ? CashBox.active : CashBox.inactive}`}
                                onClick={() => changeTableMenu('payOut')}
                                style={{borderRadius: "unset"}}
                            >
                                머니출금
                            </div>

                            <div
                                id="tab3T"
                                className={`${CashBox.cash_box_tab} ${currentTab === 'payList' ? CashBox.active : CashBox.inactive}`}
                                onClick={() => changeTableMenu('payList')}
                                style={{borderLeft: 0, borderRadius: '0 0.7vw 0.7vw 0'}}
                            >
                                내역확인
                            </div>
                        </div>


                        {(currentTab === 'payIn' || currentTab === 'payOut') && (
                            <div className={CashBox.cash_box_tab_content}>
                                <div className={CashBox.cash_box_current_tab_div}>
                                    {/* List 1 */}
                                    <div className={CashBox.cash_box_money_box_div}>
                                        <div className={CashBox.cash_box_label_box}>
                                            보유머니
                                        </div>
                                        <div className={CashBox.cash_box_money}>
                                            <div>{ToKoreanNotation(moneyData[currency].total_money, currencyUnit)}</div>
                                        </div>
                                    </div>

                                    {/* List 2 */}
                                    <div className={CashBox.cash_box_money_box_div}>
                                        <div className={CashBox.cash_box_label_box}>
                                            금고머니
                                        </div>
                                        <div className={CashBox.cash_box_money}>
                                            <div>{ToKoreanNotation(moneyData[currency].safe_money, currencyUnit)}</div>
                                        </div>
                                    </div>

                                    {/* List 3 */}
                                    <div className={CashBox.cash_box_money_box_div}>
                                        <div className={CashBox.cash_box_label_box}>
                                            게임머니
                                        </div>
                                        <div className={CashBox.cash_box_money}>
                                            <div>{ToKoreanNotation(moneyData[currency].game_money, currencyUnit)}</div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '1vw'}}>
                                    <div className={CashBox.cash_box_label_box}>{inputLabel}</div>
                                    <div className={CashBox.cash_box_right_input}>
                                        <input
                                            className={CashBox.cash_box_input_money}
                                            type="text"
                                            maxLength="22"
                                            value={inputMoney}
                                            onChange={e => handleInputChange(e.target.value)}
                                            autoComplete="off"
                                        />
                                        <span id="idPayInInputCurrency"
                                              style={{marginRight: '0px'}}>{currencyUnit}</span>
                                    </div>
                                </div>

                                <div className={CashBox.in_game_popup_btn_container}>
                                    <div
                                        className={CashBox.in_game_popup_btn}
                                        onClick={() => handleOnSubmit()}
                                    >
                                        <div className={CashBox.in_game_popup_btn_title}>
                                            {submitBtnTxt}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {currentTab === 'payList' && (
                            <div className={CashBox.cash_box_pay_list}>
                                <div className={CashBox.cash_box_right_contents}>
                                    <div className="current-tab-div">
                                        {/* List 1 */}
                                        <div className={CashBox.cash_box_money_box_div}>
                                            <div className={CashBox.cash_box_label_box}>
                                                보유머니
                                            </div>
                                            <div className={CashBox.cash_box_money} style={{backgroundColor: 'unset'}}>
                                                <div>{ToKoreanNotation(moneyData[currency].total_money, currencyUnit)}</div>
                                            </div>
                                        </div>

                                        {/* List 2 */}
                                        <div className={CashBox.cash_box_money_box_div}>
                                            <div className={CashBox.cash_box_label_box}>
                                                금고머니
                                            </div>
                                            <div className={CashBox.cash_box_money} style={{backgroundColor: 'unset'}}>
                                                <div>{ToKoreanNotation(moneyData[currency].safe_money, currencyUnit)}</div>
                                            </div>
                                        </div>

                                        {/* List 3 */}
                                        <div className={CashBox.cash_box_money_box_div}>
                                            <div className={CashBox.cash_box_label_box}>
                                                게임머니
                                            </div>
                                            <div className={CashBox.cash_box_money} style={{backgroundColor: 'unset'}}>
                                                <div>{ToKoreanNotation(moneyData[currency].game_money, currencyUnit)}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <img style={{width: '2vw', height: '20vw'}} src={divider_icon} alt=""/>
                                    </div>

                                    <div className={CashBox.cash_box_history_container}>
                                        <div className={CashBox.cash_box_history_item}>
                                            <div className={CashBox.cash_box_history_item_title}>날짜</div>
                                            <div
                                                className={CashBox.cash_box_history_item_content}>{historyData[historyCurrentIndex].d_datetransacted && historyData[historyCurrentIndex].d_datetransacted.split('T')[0]}</div>
                                        </div>

                                        <div className={CashBox.cash_box_history_item}>
                                            <div
                                                className={CashBox.cash_box_history_item_title}>{historyData[historyCurrentIndex].d_receipt ? '출금' : '입금'}</div>
                                            <div
                                                className={CashBox.cash_box_history_item_content}>{ToKoreanNotation(historyData[historyCurrentIndex].d_receipt || historyData[historyCurrentIndex].d_payment, currencyUnit)}</div>
                                        </div>

                                        <div className={CashBox.cash_box_history_item}>
                                            <div className={CashBox.cash_box_history_item_title}>금고</div>
                                            <div
                                                className={CashBox.cash_box_history_item_content}>{ToKoreanNotation(historyData[historyCurrentIndex].d_gamemoney, currencyUnit)}</div>
                                        </div>

                                        <div className={CashBox.cash_box_history_item}>
                                            <div className={CashBox.cash_box_history_item_title}>게임</div>
                                            <div
                                                className={CashBox.cash_box_history_item_content}>{historyGameName[historyData[historyCurrentIndex].d_gamename]}</div>
                                        </div>

                                        <div className={CashBox.cash_box_history_item}>
                                            <div className={CashBox.cash_box_history_item_title}>최대 50회</div>

                                            <div className={CashBox.cash_box_prev_next_buttons} onClick={onPrevClick}>
                                                <img src={left_arrow} alt="Prev"
                                                     className={CashBox.cash_box_arrow_icon_left}/>
                                                <div>이전</div>
                                            </div>

                                            <div className={CashBox.cash_box_prev_next_buttons} onClick={onNextClick}>
                                                <div>다음</div>
                                                <img src={right_arrow} alt="Next"
                                                     className={CashBox.cash_box_arrow_icon_right}/>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        )}
                    </div>

                </div>

                <div className="day_limit_bottom_txt" style={{position: 'relative', marginTop: "14vh"}}>
                    {platformMessage}
                </div>

            </div>

        </>

    );
};

export default InGameCashBox;
