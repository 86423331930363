import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import CashBox from "../css/inGamePopup.module.css";
import $ from 'jquery';

const TestingPopup = () => {
    const navigate = useNavigate();
    const [game, setGame] = useState(false);

    const gamePage = (gameName) => {
        setGame(gameName);
    };

    const openPopup = (popup) => {
        const token = localStorage.getItem('token');
        navigate(`/ingame_popup/${popup}?gameName=${game}&token=${token}`);
    };

    useEffect(() => {
        if(!localStorage.getItem('token')){
            navigate('/login', {state: {redirect: "/ingame_popup/testing"}});
        }

        $('body').css({
            'transform': 'unset',
            'min-width': 'unset',
            'zoom': 'unset',
        });
    }, []);

    return (
        <div id="include_page" className={CashBox.in_game_popup_background}>
            <div style={{
                width: '80vw',
                marginTop: '4vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                { !game && (
                    <>
                        <div className={CashBox.in_game_submit_btn} onClick={() => gamePage('sm_dualgo')}>
                            <span style={{textAlign: "center"}}>go-stop</span>
                        </div>

                        <div className={CashBox.in_game_submit_btn} onClick={() => gamePage('sm_baduki')}>
                            <span style={{textAlign: "center"}}>baduki</span>
                        </div>

                        <div className={CashBox.in_game_submit_btn} onClick={() => gamePage('sm_poker')}>
                            <span style={{textAlign: "center"}}>poker</span>
                        </div>
                    </>
                )}

                {game && (
                    <>
                        <div className={CashBox.in_game_submit_btn} onClick={() => openPopup('day_limit')}>
                            <span style={{textAlign: "center"}}>Day Limit</span>
                        </div>

                        <div className={CashBox.in_game_submit_btn} onClick={() => openPopup('cash_box')}>
                            <span style={{textAlign: "center"}}>Cash Box</span>
                        </div>

                        <div className={CashBox.in_game_submit_btn} onClick={() => openPopup('wallet')}>
                            <span style={{textAlign: "center"}}>Wallet</span>
                        </div>

                        <div className={CashBox.in_game_submit_btn} onClick={() => openPopup('free_charge')}>
                            <span style={{textAlign: "center"}}>Free Charge</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
};

export default TestingPopup;
