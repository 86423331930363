import React, {useEffect} from 'react';


const WithdrawInGame = () => {

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        localStorage.setItem('token', params.get('token'));
        window.location.href = '/my_page?page=withdraw';
    }, []);

    return ;
};

export default WithdrawInGame;
