import React, {useEffect, useState} from 'react';
import '../css/webPopup.css';
import $ from "jquery";
import popup_heading_1 from "../images/popup/popup-heading1.svg";
import close_icon from "../images/popup/closeIcon.svg";
import gold_icon_white from "../images/currency-icon-white.svg";
import egg_icon_color from "../images/egg-icon-color.svg";
import gold_icon_color from "../images/currency-color-icon.svg";
import egg_icon_white from "../images/egg-white-icon.svg";
import divider_icon from "../images/divider-icon.svg";
import left_arrow from "../images/popup/blue left arrow.svg";
import right_arrow from "../images/popup/blue right arrow.svg";
import axios from "axios";
import baseUrl from "../BaseUrl";
import ToKoreanNotation, {Popup} from "./popupComman";


const CashBoxPopup = ({gameName, rigthGamedata}) => {
    const [currentTab, setCurrentTab] = useState('payIn');
    const [currency, setCurrency] = useState('go');
    const [inputMoney, setInputMoney] = useState('');
    const [offset, setOffset] = useState(0);
    const [historyCurrentIndex, setHistoryCurrentIndex] = useState(0);
    const [unitDisplayGO, setUnitDisplayGO] = useState('');
    const [goldIcon, setGoldIcon] = useState(gold_icon_white);
    const [eggIcon, setEggIcon] = useState(egg_icon_color);
    const [inputLabel, setInputLabel] = useState('입금액');
    const [currencyUnit, setCurrencyUnit] = useState('');
    const [moneyData, setMoneyData] = useState({go: {}, al: {}});
    const [historyData, setHistoryData] = useState([{}]);
    const [submitBtnTxt, setSubmitBtnTxt] = useState('입금하기');
    const [messagePopupStatus, setMessagePopupStatus] = useState(false);
    const [popupMessage, setPopupMessage] = useState(false);
    const [dataLoad, setDataLoad] = useState(false);

    const historyGameName = {
        sm_dualgo: "SM 신맞고",
        sm_poker: "SM 7포커",
        sm_baduki: "SM 바둑이",
        sm_smpl: "SMPL"
    };

    const closeCashPopup = () => {
        $('html').css('overflow', 'scroll');
        $(".cashfree_popup").css('display', 'none');

        rigthGamedata();
    };

    const changeCurrency = (currency_type) => {
        setCurrency(currency_type);
        setOffset(0);
        setHistoryCurrentIndex(0);
        setHistoryData([{}]);
        changeTableMenu('payIn');

        if (currency_type === 'go') {
            setGoldIcon(gold_icon_white);
            setEggIcon(egg_icon_color);
            setCurrencyUnit(unitDisplayGO);
        } else {
            setGoldIcon(gold_icon_color);
            setEggIcon(egg_icon_white);
            setCurrencyUnit('알');
        }

    };

    const changeTableMenu = (tab) => {
        setCurrentTab(tab);
        if (tab === "payIn") {
            setInputLabel("입금액");
            setSubmitBtnTxt('입금하기');
        } else if (tab === "payOut") {
            setInputLabel("출금액");
            setSubmitBtnTxt('출금하기');
        } else {
            setOffset(0);
            fetchCashBoxHistory(0, "table_change");
        }
        setInputMoney('');
    };


    const handleInputChange = (value) => {
        var money = parseInt(value.replace(/([만억조])/gi, '').replace(/,/g, ''));

        console.log(money);
        if (money < 0 || isNaN(money)) {
            setInputMoney('');
            return;
        }

        if (currentTab === "payIn") {
            if (money > moneyData[currency].game_money) {
                inputToKoreanNotation(moneyData[currency].game_money);
                return;
            }
        } else {
            if (money > moneyData[currency].safe_money) {
                inputToKoreanNotation(moneyData[currency].safe_money);
                return;
            }
        }

        inputToKoreanNotation(money);
    };

    function inputMoneyRemoveKorean(money) {
        return money.replace(/만/gi, "").replace(/억/gi, "").replace(/조/gi, "");
    }

    function inputToKoreanNotation(money) {
        money = money.toString();
        var str1, str2, str3, str4, sum;

        if (money.length <= 4) {
            sum = money;
        } else if (money.length > 4 && money.length < 9) {
            //만
            str1 = money.slice(-4);
            str2 = money.slice(-money.length, -4);
            sum = str2 + '만' + str1;

        } else if (money.length > 8 && money.length < 13) {
            //억
            str1 = money.slice(-4);
            str2 = money.slice(-8, -4);
            str3 = money.slice(-money.length, -8);
            sum = str3 + '억' + str2 + '만' + str1;

        } else if (money.length > 12 && money.length < 17) {
            //조
            str1 = money.slice(-4);
            str2 = money.slice(-8, -4);
            str3 = money.slice(-12, -8);
            str4 = money.slice(-money.length, -12);
            sum = str4 + '조' + str3 + '억' + str2 + '만' + str1;

        }

        setInputMoney(sum);
    }


    const fetchMoneyData = async () => {
        try {
            const response = await axios.get(baseUrl + 'popups/cash_box/', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                setMoneyData(response.data);
            }

        } catch (e) {

        }
    };


    const fetchCashBoxHistory = async (page_offset, table_change = null) => {
        try {
            const response = await axios.post(baseUrl + 'popups/cash_box_history/', {
                    currency: currency,
                    offset: page_offset
                },

                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                });

            if (response.status === 200) {
                setDataLoad(true);

                if (response.data.length > 0) {
                    if (table_change) {
                        setHistoryCurrentIndex(0);
                        setHistoryData(response.data);
                    } else if (historyData.length === 1) {
                        setHistoryData(response.data);
                    } else {
                        const newArray = historyData.concat(response.data);
                        setHistoryData(newArray);
                        setHistoryCurrentIndex(historyCurrentIndex + 1);
                    }
                }
            }

        } catch (e) {

        }
    };


    async function handleOnSubmit() {
        if (inputMoney < 1 || !inputMoney) {
            setMessagePopupStatus(false);

            if (currentTab === "payIn")
                setPopupMessage("입금하실 금액을 입력해 주세요.");
            else
                setPopupMessage("출금하실 금액을 입력해 주세요.");

            $('.message-main-pop').css("display", "block");
            return;
        }

        const data = {
            currency: currency,
            amount: inputMoneyRemoveKorean(inputMoney),
            game_name: gameName,
            category: currentTab,
        };

        setInputMoney('');

        try {
            const response = await axios.post(baseUrl + 'popups/update_cash_box_money/', data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                sessionStorage.clear();
                setMessagePopupStatus(true);
                if (currentTab === "payIn"){
                    setPopupMessage("머니 입금이 완료되었습니다.");
                } else {
                    setPopupMessage("머니 출금이 완료되었습니다.");
                }
                $('.message-main-pop').css("display", "block");
                setMoneyData(response.data);
            }
            else if(response.status === 204) {
                setMessagePopupStatus(false);
                setPopupMessage("게임 진행 중에는 금고 입•출금이 제한 됩니다.");
                $('.message-main-pop').css("display", "block");
            }

        } catch (e) {
            console.log(e);
            setMessagePopupStatus(false);
            setPopupMessage("Internal Server Error");
            $('.message-main-pop').css("display", "block");
        }


    }

    function onPrevClick() {
        if (historyCurrentIndex >= 1) {
            setHistoryCurrentIndex(historyCurrentIndex - 1);
        } else {
            setMessagePopupStatus(false);
            setPopupMessage("최초의 입출금 내역입니다.");
            $('.message-main-pop').css("display", "block");
        }
    }

    function onNextClick() {
        if (historyCurrentIndex <= 49) {
            if (historyCurrentIndex + 1 === historyData.length) {
                if (historyData.length === (10 + offset)) {
                    setOffset(offset + 10);
                    setDataLoad(false);
                    fetchCashBoxHistory(offset + 10);
                } else if (dataLoad) {
                    setMessagePopupStatus(false);
                    setPopupMessage("입출금 내역이 없습니다.");
                    $('.message-main-pop').css("display", "block");
                }
            } else {
                setHistoryCurrentIndex(historyCurrentIndex + 1);
            }
        } else {
            setMessagePopupStatus(false);
            setPopupMessage("내역은 최대 50개까지만 보여집니다");
            $('.message-main-pop').css("display", "block");
        }
    }


    useEffect(() => {
        fetchMoneyData();

        $('.cashfree_popup').css("display", "block");


        if (gameName === "sm_dualgo") {
            setUnitDisplayGO("냥");
            setCurrencyUnit("냥");
        } else {
            setUnitDisplayGO("골드");
            setCurrencyUnit("골드");
        }
    }, [gameName]);


    return (
        <div id="include_page" className="cashfree_popup">
            <div className="popup-container">
                <div className="popup-heading">
                    <img loading="lazy" src={popup_heading_1} alt=""/>
                    <div className="close-icon" onClick={closeCashPopup}>
                        <img loading="lazy" src={close_icon} alt=""/>
                    </div>
                </div>

                <div className="inner-container">
                    <div className="currency-switch">
                        <div
                            onClick={() => changeCurrency('go')}
                            className="currency-option"
                            style={{borderRight: '0', backgroundColor: currency === 'go' ? '#004279' : 'white'}}
                        >
                            <img loading="lazy" src={goldIcon} alt="currency-symbol-1" style={{width: '20px', height: '20px'}}/>
                            <p style={{
                                color: currency === 'go' ? '#fff' : '#004985',
                                fontSize: '20px'
                            }}>{unitDisplayGO}</p>
                        </div>
                        <div
                            onClick={() => changeCurrency('al')}
                            className="currency-option"
                            style={{backgroundColor: currency === 'al' ? '#004279' : 'white'}}
                        >
                            <img loading="lazy" src={eggIcon} alt="currency-symbol-2" style={{width: '20px', height: '20px'}}/>
                            <p style={{color: currency === 'al' ? '#fff' : '#004985', fontSize: '20px'}}>알</p>
                        </div>
                    </div>

                    <div className="contents-inner-box">
                        <div
                            id="tab1T"
                            className={`tablinks2 ${currentTab === 'payIn' ? 'active' : 'inactive'}`}
                            onClick={() => changeTableMenu('payIn')}
                            style={{borderRight: 0, borderRadius: '8px 0px 0px 8px'}}
                        >
                            머니입금
                        </div>

                        <div
                            id="tab2T"
                            className={`tablinks2 ${currentTab === 'payOut' ? 'active' : 'inactive'}`}
                            onClick={() => changeTableMenu('payOut')}
                            style={{borderRadius: "unset"}}
                        >
                            머니출금
                        </div>

                        <div
                            id="tab3T"
                            className={`tablinks2 ${currentTab === 'payList' ? 'active' : 'inactive'}`}
                            onClick={() => changeTableMenu('payList')}
                            style={{borderLeft: 0, borderRadius: '0px 8px 8px 0px'}}
                        >
                            내역확인
                        </div>


                        {(currentTab === 'payIn' || currentTab === 'payOut') && (
                            <div className="tab-content">
                                <div className="current-tab-div">
                                    {/* List 1 */}
                                    <div style={{display: 'flex', gap: '8px'}}>
                                        <div className="label-box">
                                            보유머니
                                        </div>
                                        <div className="money-box">
                                            <div>{ToKoreanNotation(moneyData[currency].total_money, currencyUnit)}</div>
                                        </div>
                                    </div>

                                    {/* List 2 */}
                                    <div className="money-box-div">
                                        <div className="label-box">
                                            금고머니
                                        </div>
                                        <div className="money-box">
                                            <div>{ToKoreanNotation(moneyData[currency].safe_money, currencyUnit)}</div>
                                        </div>
                                    </div>

                                    {/* List 3 */}
                                    <div className="money-box-div">
                                        <div className="label-box">
                                            게임머니
                                        </div>
                                        <div className="money-box">
                                            <div>{ToKoreanNotation(moneyData[currency].game_money, currencyUnit)}</div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '8px'}}>
                                    <div className="label-box">{inputLabel}</div>
                                    <div className="CashBox_RightInputBox2" style={{padding: '0px 10px'}}>
                                        <input
                                            className="InputMONEY"
                                            type="text"
                                            maxLength="22"
                                            value={inputMoney}
                                            onChange={e => handleInputChange(e.target.value)}
                                            autoComplete="off"
                                        />
                                        <span id="idPayInInputCurrency"
                                              style={{marginRight: '0px'}}>{currencyUnit}</span>
                                    </div>
                                </div>

                                <div className="web-popup-btn-container">
                                    <div
                                        className="web-popup-btn"
                                        onClick={() => handleOnSubmit()}
                                    >
                                        <div className="web-popup-btn-title">
                                            {submitBtnTxt}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {currentTab === 'payList' && (
                            <div id="tab3-1" className="tabcontent2">
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    position: 'relative'
                                }}>
                                    <div className="CashBoxRightContents">
                                        <div className="current-tab-div">
                                            {/* List 1 */}
                                            <div style={{display: 'flex', gap: '8px'}}>
                                                <div className="label-box">
                                                    보유머니
                                                </div>
                                                <div className="money-box">
                                                    <div>{ToKoreanNotation(moneyData[currency].total_money, currencyUnit)}</div>
                                                </div>
                                            </div>

                                            {/* List 2 */}
                                            <div className="money-box-div">
                                                <div className="label-box">
                                                    금고머니
                                                </div>
                                                <div className="money-box">
                                                    <div>{ToKoreanNotation(moneyData[currency].safe_money, currencyUnit)}</div>
                                                </div>
                                            </div>

                                            {/* List 3 */}
                                            <div className="money-box-div">
                                                <div className="label-box">
                                                    게임머니
                                                </div>
                                                <div className="money-box">
                                                    <div>{ToKoreanNotation(moneyData[currency].game_money, currencyUnit)}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <img loading="lazy" src={divider_icon} alt=""/>
                                        </div>

                                        <div className="history-container-1">
                                            <div className="history-container-2">
                                                <div className="history-item">
                                                    <div className="history-item-title">날짜</div>
                                                    <div
                                                        className="history-item-content">{historyData[historyCurrentIndex].d_datetransacted && historyData[historyCurrentIndex].d_datetransacted.split('T')[0]}</div>
                                                </div>

                                                <div className="history-item">
                                                    <div
                                                        className="history-item-title">{historyData[historyCurrentIndex].d_receipt ?  '출금' : '입금' }</div>
                                                    <div
                                                        className="history-item-content">{ToKoreanNotation(historyData[historyCurrentIndex].d_receipt || historyData[historyCurrentIndex].d_payment, currencyUnit)}</div>
                                                </div>

                                                <div className="history-item">
                                                    <div className="history-item-title">금고</div>
                                                    <div
                                                        className="history-item-content">{ToKoreanNotation(historyData[historyCurrentIndex].d_gamemoney, currencyUnit)}</div>
                                                </div>

                                                <div className="history-item">
                                                    <div className="history-item-title">게임</div>
                                                    <div
                                                        className="history-item-content">{historyGameName[historyData[historyCurrentIndex].d_gamename]}</div>
                                                </div>

                                                <div className="history-item">
                                                    <div className="history-item-title">최대 50회</div>
                                                    <div className="prev-next-buttons">
                                                        <div className="prev-next-button" onClick={onPrevClick}>
                                                            <img loading="lazy" src={left_arrow} alt="Prev"
                                                                 className="arrow-icon-left"/>
                                                            <div>이전</div>
                                                        </div>
                                                        <div className="prev-next-button" onClick={onNextClick}>
                                                            <div>다음</div>
                                                            <img loading="lazy" src={right_arrow} alt="Next"
                                                                 className="arrow-icon-right"/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
                    <Popup status={messagePopupStatus} popupMessage={popupMessage}/>

            </div>

        </div>
    );
};

export default CashBoxPopup;
