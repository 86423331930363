import React from 'react';
import '../css/membership.css';
import icon_button from "../images/mypage_img/Icon-button.png";
import icon_exclamation from "../images/membership_img/Icon-exclamation.png";
import {useNavigate} from "react-router-dom";
import SubHeader from "./authSubHeader";
import SubFooter from "../home/subFooter";

const MembershipReconfirm = () => {
    const navigate = useNavigate();

    return (
        <>
            <SubHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="TopMysection">
                    <div className="TopMysection_inner">
                        <div className="TopMysection_title">본인확인</div>
                    </div>
                </div>

                {/* Membership Agreement */}
                <div id="contents">
                    <div className="Centerwarp" >
                        <div className="Centerwarp_inner" style={{height: '700px'}}>
                            <div className="Reconfirm_Box">
                                <div className="Reconfirm_img">
                                    <img src={icon_exclamation} alt="Exclamation Icon"/>
                                </div>
                                <div className="Reconfirm_inner">
                                    <span style={{fontSize: '20px', color: '#15a3e1'}}>본인확인 후</span>
                                    <span style={{fontSize: '20px'}}> 이용하실 수 있습니다.</span>
                                    <div className="contentsinterval"></div>
                                    <p style={{color: '#7b7b7b'}}>
                                        - 웹보드 게임 이용자는 연1회 본인확인이 필요합니다.
                                        <br/>
                                        - 본인확인 완료 후 즉시 게임 이용이 가능합니다.
                                    </p>
                                </div>
                                <div className="Reconfirm_GrayBox">
                                    <div className="Reconfirm_inner" style={{marginLeft: '118px'}}>
                                        <p style={{color: '#7b7b7b'}}>
                                            * 웹보드 게임 중 고스톱게임류 게임 전체에 해당합니다.
                                            <br/>
                                            * 연 1회 본인 확인은 게임법에 따라 시행됩니다.
                                        </p>
                                        <br/>
                                        <p style={{color: '#c10000', fontWeight: 'bold'}}>
                                            서비스 이용을 위해 본인확인을 하시겠습니까?
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="oknoBox_button">
                                <button className="okno_button" onClick={() => navigate('/membership_verify')}>동의
                                    <div className="okno_button_img"><img src={icon_button} alt=""/></div>
                                </button>
                                <button className="okno_button" type="button" onClick={() => navigate('/')}
                                        style={{backgroundColor: '#4e4e4e', marginLeft: '3px'}}>취소
                                    <div className="okno_button_img"><img src={icon_button} alt=""/></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SubFooter/>

        </>

    );
};

export default MembershipReconfirm;
