import React, {useEffect, useState} from "react";
import axios from "axios";
import baseUrl from "../BaseUrl";
import ServiceCenterHeader from "./serviceCenterHeader";
import RightGamesWithoutLogin from "../game/rightGamesWithoutLogin";
import SubFooter from "../home/subFooter";
import {useNavigate} from "react-router-dom";

const renderDivision = (division) => {
    switch (division) {
        case 0:
            return '[설치/실행]';
        case 1:
            return '[계정]';
        case 2:
            return '[결제]';
        case 3:
            return '[이벤트]';
        case 4:
            return '[기타]';
        default:
            return '[알 수 없음]';
    }
};

const ManToManContent = () => {
    const navigate = useNavigate();
    const [manToManContent, setManToManContent] = useState(false);
    const params = new URLSearchParams(window.location.search);
    const requestId = params.get('requestId');


    const showContent = async () => {
        try {
            const response = await axios.get(baseUrl + 'service_center/customer_request_content/?request_id=' + requestId, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (response.status === 200) {
                setManToManContent(response.data[0]);
            }
        } catch (e) {

        }
    };


    useEffect(() => {
        showContent();
    }, [requestId]);

    return (
        <>
            <ServiceCenterHeader category="inquiry" page="statement"/>

            <div className="ServiceCenterWarpMain">
                <div className="ServiceGameCenterWarp" style={{height: 'auto'}}>
                    <div className="GameCenterwarp_inner" style={{height: 'auto', paddingBottom: '100px'}}>
                        <div className="subMaintitleBox">
                            <div className="subMainGrayBox"/>
                            <div className="subMaintitle">이메일 접수내역</div>

                            <div className="subMainDepth">고객센터 > 문의/제안하기 > 이메일 접수내역</div>
                        </div>

                        {manToManContent && (<div className="email_contents_box">
                            <div className="email_contents_date_box">
                                {manToManContent.writeDate.split('T')[0]}
                            </div>
                            <div className="email_contents_title_box">
                                <button
                                    className="quit_ok_no_button"
                                    style={{position: 'relative', margin: '27px 0 0 0'}}
                                >
                                    Q
                                </button>
                                <div style={{display: 'inline-block', marginTop: '27px', marginLeft: '5px'}}>
                                    <em style={{color: '#15a3e1', fontWeight: 'bold'}}>{manToManContent.title}</em>
                                    <span className="info_txt_bar"
                                          style={{backgroundColor: '#c0c0c0', height: '15px'}}></span>
                                    <em style={{
                                        color: '#4e4e4e',
                                        fontSize: '16px',
                                        fontWeight: 'normal'
                                    }}>{renderDivision(manToManContent.division)}</em>
                                </div>
                            </div>
                            <div className="email_q_text_box" style={{ whiteSpace: 'pre-wrap' }}>
                                {manToManContent.contents}
                            </div>
                            <div className="email_contents_date_box" style={{width: 'auto', marginLeft: '750px'}}>
                                {/* Empty div for spacing or alignment */}
                            </div>
                            <div className="email_contents_date_box">
                                {/* Another empty div for spacing or alignment */}
                            </div>
                            <div className="email_gray_box">
                                <div className="email_contents_title_box" style={{height: 'auto', border: 'none'}}>
                                    <button
                                        className="quit_ok_no_button"
                                        style={{position: 'relative', backgroundColor: '#4e4e4e', margin: '72px 0 0 0'}}
                                    >
                                        A
                                    </button>
                                    <div style={{display: 'inline-block', width: '900px', margin: '72px 0 27px 5px'}}>
                                        {manToManContent.answer === null ? '답변대기중 입니다. 빠른 시일내에 답변해 드리겠습니다.' : manToManContent.answer}
                                    </div>
                                </div>
                            </div>
                        </div>)}

                        <div className="notice_paging_box" style={{justifyContent: 'center'}}>
                            <div className="notice_paging_box_hover" style={{width: '950px', cursor: "pointer"}} onClick={() => navigate('/service_center/inquiry?page=statement')}>
                                <span style={{fontSize: '15px'}}>
                                    목록으로
                                </span>
                            </div>
                        </div>
                    </div>

                    <RightGamesWithoutLogin/>
                </div>
            </div>

            <SubFooter/>

        </>


    );

};

export default ManToManContent;
