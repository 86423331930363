import React, {useEffect, useState} from "react";
import MobileHeader from "./mHeader";
import {useNavigate} from "react-router-dom";
import MobileVerification from "../mobileVerify/mobileVerification";
import $ from 'jquery';
import axios from "axios";
import baseUrl from "../BaseUrl";
import MobileFooter from "./mFooter";

const MobileFienPassword = () => {
    const navigate = useNavigate();
    const [requestData, setRequestData] = useState(false);
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');
    const changePwToken = params.get('changePwToken');

    const [inputId, setInputId] = useState('');
    const [pw, setPw] = useState('');
    const [pwOk, setPwOk] = useState('');
    const [findPassword, setFindPassword] = useState(true);
    const [isIdValid, setIsIdValid] = useState();

    const searchId = (e) => {
        let inputVal = e.target.value.replace(/\s/g, '');
        let timer;

        // Remove special characters
        inputVal = inputVal.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=ㄱ-ㅎㅏ-ㅣ|가-힣]/gi, '');

        // Disallow starting with a number
        if (/^\d/.test(inputVal)) {
            inputVal = inputVal.slice(1);
        }

        setInputId(inputVal);

        // Clear previous timer to avoid multiple API calls
        $("#change_pw_content_2").html("");
        clearTimeout(timer);

        // Set timer to make API call after 10 seconds
        if (inputVal) {
            timer = setTimeout(async () => {
                try {
                    const response = await axios.get(baseUrl + 'search_user_id/?user_id='+ inputVal);
                    if (response.status === 200) {
                        setIsIdValid(true);
                        $('#change_pw_title').css('display', 'none');
                        $('#change_pw_content').css('display', 'none');
                        $('#change_pw_content_2').html('<span style="color: #979797;">존재하는 아이디입니다.</span>');
                    }

                } catch (error) {
                    setIsIdValid(false);
                    $('#change_pw_title').css('display', 'none');
                    $('#change_pw_content').css('display', 'none');
                    $('#change_pw_content_2').html('<span style="color: #cd642b;">존재하지 않는 아이디입니다.</span>');
                }

            }, 100); // 10 seconds
        } else {
            setIsIdValid(false);
            $('#change_pw_content_2').html('');
            $('#change_pw_title').css('display', 'block');
            $('#change_pw_content').css('display', 'block');
        }
    };


    const searchUser = async (e) => {
        if (!inputId){
            return alert('아이디를 입력해주세요.');
        }

        if (!isIdValid){
            return alert('존재하지 않는 아이디입니다.');
        }

        try {
            const response = await axios.get(baseUrl + 'search_user_id/?user_id=' + inputId);
            if (response.status === 200) {
                setRequestData({
                    page: 'mobileChangePassword',
                    user_id: inputId
                });
            }

        } catch (error) {
            setInputId('');
            $('#change_pw_title').css('display', 'none');
            $('#change_pw_content').css('display', 'none');
            $('#change_pw_content_2').html('<span style="color: #cd642b;">존재하지 않는 아이디입니다.</span>');
        }

    };

    const validatePassword = (password) => {
      const rules = [
        { regex: /.{6,15}/ },  // Password length between 6 and 15 characters
        { regex: /[A-Za-z]/ },    // At least one letter
        { regex: /[0-9]/ },    // At least one digit
        { regex: /[!@#$%^&*(),.?":{}|<>]/ }  // At least one special character
      ];

      for (let rule of rules) {
        if (!rule.regex.test(password)) {
          return false;
        }
      }

      return true;
    };

    const handlePwChange = (e) => {
        const inputVal = e.target.value.replace(/\s/g, '');
        const result = inputVal.replace(/[ \ㄱ-ㅎㅏ-ㅣ|가-힣 ]/gi, '');
        setPw(result);
    };

    const handlePwOkChange = (e) => {
        const inputVal = e.target.value.replace(/\s/g, '');
        const result = inputVal.replace(/[ \ㄱ-ㅎㅏ-ㅣ|가-힣 ]/gi, '');
        setPwOk(result);
    };


    const changePassword = async () => {
        if (!pw) {
            return alert('비밀번호를 입력해 주세요.');
        }
        else if(!validatePassword(pw)){
            return alert('비밀번호는 최소 6글자, 최대 15글자의 영문 + 숫자 + 특수문자만\n입력 가능합니다.');
        }
        else if (!pwOk) {
            return alert('비밀번호 확인을 입력해 주세요.');
        }
        else if (pw !== pwOk) {
            return alert('비밀번호와 비밀번호 확인이 서로 다릅니다.');
        }

        try {
            const data = {
                'api_token': changePwToken,
                'password': pw,
                'conform_password': pwOk
            };

            const response = await axios.post(baseUrl + 'mobile_verification/change_password/', data);

            if (response.status === 200) {
                alert('비밀번호가 변경되었습니다.');
                window.location.href = '/mobile/login';
            }

        } catch (error) {
            alert('비밀번호가 변경 실패.');
            window.location.href = '/mobile/login';
        }
    };

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });

        if (page === "changePassword") {
            setFindPassword(false);
            $('#find_pw_next_button').css('display', 'none');
            $('#find_pw_check_button').css('display', 'block');
        } else if (page === "invalidUserId") {
            alert('입력하신 정보와 모두 일치하는 아이디가 없습니다.');
        }

    }, []);

    return (
        <>
            <MobileHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>로그인</span>
                </div>

                <div className="m_center_warp">
                    <div className="find_id_title_box">
                        비밀번호 찾기
                    </div>

                    <div className="find_id_box">


                        {findPassword && (<div className="find_id_inner pw">
                                <div className="find_pw_passward_box">
                                    <div className="find_pw_input_box">
                                        <input
                                            type="text"
                                            placeholder="아이디를 입력해주세요."
                                            style={{imeMode: 'inactive'}}
                                            maxLength="11"
                                            autoComplete="off"
                                            value={inputId}
                                            onChange={searchId}
                                        />
                                    </div>
                                </div>


                                <div className="find_id_mobile_img">
                                    <img src={require("../images/membership_img/Icon-mobile.jpg")}/>
                                </div>
                                <em className="find_id_title" id="change_pw_title">휴대폰 인증<br/></em>
                                <div className="find_id_contents" id="change_pw_content">휴대폰 인증 후 비밀번호를 찾으실 수 있습니다.
                                </div>
                                <div className="find_id_contents" id="change_pw_content_2"
                                     style={{position: 'absolute', margin: '2.0313vw 3.125vw 0px 7.8125vw'}}></div>
                            </div>
                        )}


                        {!findPassword && (<div className="find_id_inner pw">
                            <div style={{fontSize: '3.1250vw', fontWeight: 'bold', margin: '-2vw 0 0 -6vw'}} >비밀번호 변경</div>

                            <div className="find_pw_passward_box" style={{marginTop: '1.6vw'}}>
                                <div className="find_pw_input_box">
                                    <input
                                        type="password"
                                        placeholder="변경할 비밀번호를 입력해주세요."
                                        style={{imeMode: 'inactive'}}
                                        maxLength="11"
                                        autoComplete="off"
                                        value={pw}
                                        onChange={handlePwChange}
                                    />
                                </div>
                            </div>


                            <div className="find_pw_passward_box">
                                <div className="find_pw_input_box">
                                    <input
                                        type="password"
                                        placeholder="한번 더 입력해주세요."
                                        style={{imeMode: 'inactive'}}
                                        maxLength="11"
                                        autoComplete="off"
                                        value={pwOk}
                                        onChange={handlePwOkChange}
                                    />
                                </div>
                            </div>

                        </div>)}


                    </div>

                    <div className="m_ok_btn_box" style={{display: "flex", justifyContent: "center"}}>
                        <button className="m_ok_no_button" id="find_pw_next_button" onClick={searchUser}>
                            다음
                            <div className="m_ok_no_button_img"/>
                        </button>

                        <button className="m_ok_no_button" id="find_pw_check_button"
                                onClick={changePassword} style={{display: 'none'}}>
                            다음
                            <div className="m_ok_no_button_img"/>
                        </button>

                        <button className="m_ok_no_button" id="find_pw_cancel_button" type="button"
                                onClick={() => navigate('/mobile/login')}
                                style={{backgroundColor: '#4e4e4e', marginLeft: '0.4688vw'}}>취소
                            <div className="m_ok_no_button_img"/>
                        </button>
                    </div>

                </div>
            </div>

            {requestData && (<MobileVerification requestData={requestData}/>)}

            <MobileFooter/>

        </>
    )
}

export default MobileFienPassword