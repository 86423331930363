import React, {useEffect} from "react";
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";
import MobileBagAvatar from "./mBagAvatar";

const MobileBag = () => {
    const navigate = useNavigate();


    const verifyToken = async () => {
        try {
            const response = await axios.get(baseUrl + 'verify_token', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
        } catch (e) {
            navigate('/mobile/login');
        }
    };

    useEffect(() => {
        verifyToken();
    }, []);

    return (
        <>
            <div style={{background: '#f5f5f5'}}>
                <MobileHeader/>

                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>내지갑</span>
                </div>

                <div className="m_shop_contents">
                    <MobileBagAvatar/>
                </div>

            </div>
            
            <MobileFooter/>

        </>

    );
}

export default MobileBag;