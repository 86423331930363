import React, {useEffect, useState} from 'react';
import "../css/serviceCenter.css";
import icon_page_prev from "../images/servicecenter_img/Icon-page_prev.png";
import icon_page_next from "../images/servicecenter_img/Icon-page_next.png";
import axios from "axios";
import baseUrl from "../BaseUrl";
import $ from 'jquery';
import RightGamesWithoutLogin from "../game/rightGamesWithoutLogin";
import SubFooter from "../home/subFooter";
import ServiceCenterHeader from "./serviceCenterHeader";

const getDivisionText = (division) => {

    switch (division) {
        case 0:
            return '[공지]';
        case 1:
            return '[이벤트]';
        case 2:
        case 3:
        case 4:
            return '[점검]';
        default:
            return '';
    }
};


const ServiceNoticesContent = () => {
    const [noticeContent, setNoticeContent] = useState({});
    const params = new URLSearchParams(window.location.search);
    const notice_id = params.get('notice_id');
    const [pageIndex, setPageIndex] = useState(1);

    const noticeData = async (noticeId) => {
        try {
            const response = await axios.get(baseUrl + 'service_center/notice_content/?notice_id='+noticeId);
            if (response.status === 200) {
                setNoticeContent(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    function onPrevClick() {
        if(noticeContent) {
            if (pageIndex < noticeContent.notice_count){
                setPageIndex(pageIndex + 1);
                noticeData(pageIndex + 1);
            } else {
                alert('첫번째글 입니다.');
            }
        }
    }

    function onNextClick() {
        if (pageIndex > 1){
            setPageIndex(pageIndex - 1);
            noticeData(pageIndex - 1);
        } else {
            alert('마지막글 입니다.');
        }
    }


    useEffect(() => {
        noticeData(notice_id);
        setPageIndex(parseInt(notice_id));
        $('.ServiceGameCenterWarp').css('height', 'auto');

    }, [notice_id]);

    return (
        <>

            <ServiceCenterHeader category={"notice_content"}/>

            <div className="ServiceCenterWarpMain">
                <div className="ServiceGameCenterWarp">
                    <div className="GameCenterwarp_inner">
                        <div className="subMaintitleBox">
                            <div className="subMainGrayBox"/>
                            <div className="subMaintitle">공지사항</div>

                            <div className="subMainDepth">고객센터 > 공지사항</div>
                        </div>


                        <div className="notice_contents_box">
                            <div className="category_title_box">
                                <div
                                    className="service_category_title">{getDivisionText(noticeContent.division)}{noticeContent.title}</div>
                            </div>

                            <div className="category_box_sub_title">
                                <div className="category_box_sub_title_2"
                                     dangerouslySetInnerHTML={{__html: noticeContent.contents}}>
                                </div>
                                <div style={{height: '30px', borderBottom: '1px solid #efefef'}}/>
                            </div>


                        </div>


                        <div className="notice_paging_box">
                            <div style={{fontSize: '18px', width: '77%', cursor: 'pointer'}}
                                 onClick={() => window.location.href = '/service_center/notice'}>
                                목록으로
                            </div>

                            <div style={{display: "flex"}}>
                                <div className="service_content_paging_button service_rigth_paging"
                                     onClick={onPrevClick}>
                                    <img src={icon_page_prev} alt=""/>
                                    <span>이전글</span>
                                </div>

                                <div className="service_content_paging_button" style={{paddingLeft: '15px'}}
                                     onClick={onNextClick}>
                                    <span>다음글</span>
                                    <img src={icon_page_next} alt=""/>
                                </div>
                            </div>

                        </div>
                    </div>

                    <RightGamesWithoutLogin/>
                </div>
            </div>

            <SubFooter/>
        </>
    );
};

export default ServiceNoticesContent;
