import axios from "axios";
import baseUrl from "../BaseUrl";

const VerifyToken = async () => {
    if (localStorage.getItem('token')) {
        try {
            await axios.get(baseUrl + 'verify_token', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            return true;

        } catch (e) {
            localStorage.removeItem('token');
            return false;
        }
    } else {
        sessionStorage.setItem('tokenVerified', false);
        return false;
    }

};

export default VerifyToken;