import React, {useEffect} from 'react';
import "../css/serviceCenter.css";
import "../css/myPage.css";
import axios from "axios";
import baseUrl from "../BaseUrl";
import MyAvatar from "./myAvatar";


const MyWallet = () => {

    const myWalletData = async () => {
        try {
            await axios.get(baseUrl + 'my_page/basic_info/', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        myWalletData();
    }, []);


    return (
        <>
            <div className="subMaintitleBox">
                <div className="subMainGrayBox" />
                <div className="subMaintitle">내지갑</div>

                <div className="subMainDepth">내정보 > 내지갑</div>
            </div>

            <MyAvatar/>

        </>
    );
};

export default MyWallet;
