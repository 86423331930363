import React from 'react';

const App = () => {
    // Inline CSS for the sticky header and other elements
    const headerStyle = {
        position: 'sticky',
        top: '0',
        backgroundColor: '#333',
        color: 'white',
        padding: '10px 0',
        textAlign: 'center',
        zIndex: '1000',
        width: '100%',
    };

    const mainStyle = {
        padding: '20px',
    };

    const contentStyle = {
        height: '2000px', // Just for demonstration to enable scrolling
    };

    return (
        <div>
            <header style={headerStyle}>
                <h1>My Sticky Header</h1>
            </header>
            <main style={mainStyle}>
                <p>Scroll down to see the sticky header in action.</p>
                <div style={contentStyle}>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
                    {/* Add more content here */}
                </div>
            </main>
        </div>
    );
};

export default App;
