import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';

import React, {useRef, useState, useEffect} from "react";
import {A11y, Autoplay, Navigation, Pagination, Scrollbar,} from "swiper/modules";
import axios from "axios";
import baseUrl from "../BaseUrl";
import Icon_arrow_top from "../images/Icon-arrow-top.png";
import Icon_arrow_bottom from "../images/Icon-arrow-bottom.png";

function FooterNoticeSlider() {
    const [notice, setNotice] = useState([]);
    const swiperRef = useRef(null);

    const noticeData = async () => {
        try {
            const response = await axios.get(baseUrl + "home/notice");
            if (response.status === 200) {
                setNotice(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    function getDivisionLabel(division) {
        let label;
        switch (division) {
            case 0:
                label = "[공지]";
                break;
            case 1:
                label = "[이벤트]";
                break;
            case 2:
            case 3:
            case 4:
                label = "[점검]";
                break;
            default:
                label = "[]";
        }
        return label;
    }

    const serviceCenter = (noticeId) => {
        window.location.href = `/service_center/notice_content?notice_id=${noticeId}`;
    };

    const goToNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            swiperRef.current.swiper.autoplay.start();

        }
    };

    const goToPrevSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            swiperRef.current.swiper.autoplay.start();

        }
    };

    useEffect(() => {
        noticeData();
    }, []);

    return (
        <>
            <div className="footer-container" id="slide">
                <div onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
                     onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}>
                    <Swiper
                        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y,]}
                        spaceBetween={1}
                        slidesPerView={1}
                        direction="vertical"
                        style={{height: 200}}
                        navigation={true}
                        ref={swiperRef}
                        loop={true}
                        speed={1000}
                        autoplay={{delay: 2000, disableOnInteraction: false}}
                    >

                        <div className="game-swiper1">

                            {notice.map(slide => (
                                <SwiperSlide key={slide.id}>
                                    <div className="footer-notice" onClick={() => serviceCenter(slide.notice_id)}>
                                        {getDivisionLabel(slide.division)} {slide.title}
                                    </div>
                                </SwiperSlide>
                            ))}

                        </div>

                    </Swiper>

                </div>

            </div>

            <div style={{height: '100%'}}>
                <div style={{cursor: "pointer"}} onClick={goToNextSlide}>
                    <img src={Icon_arrow_top} className="info_Topsection_inner1_img"/>
                </div>
                <div style={{cursor: "pointer"}} onClick={goToPrevSlide}>
                    <img src={Icon_arrow_bottom} className="info_Topsection_inner1_img2"/>
                </div>
            </div>

        </>
    );

}

export default FooterNoticeSlider;