import React, {useEffect, useState} from "react";
import $ from 'jquery';
import QAPage from "./QA";
import {useNavigate} from "react-router-dom";
import ServiceCenterHeader from "./serviceCenterHeader";
import RightGamesWithoutLogin from "../game/rightGamesWithoutLogin";
import SubFooter from "../home/subFooter";

function SearchForHelp() {
    const [searchText, setSearchText] = useState('');
    const [showQA, setShowQA] = useState(false);
    const [qaPage, setQAPage] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [headerMainPage, setHeaderMainPage] = useState(false);
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');

    const handleValueChange = (event) => {
        setSelectedValue(event.target.value);
    };


    const handleSearchClick = () => {
        setShowQA(true);
        setQAPage(<QAPage gameName={'other'} searchTxt={searchText} searchDiv={selectedValue}/>);
    };

    function handleSearchTextChange(e) {
        const inputVal = e.target.value;
        const cleanedValue = inputVal.replace(/[<>''&]/gi, '');
        setSearchText(cleanedValue);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    function ReadyPopup() {
        alert('준비중입니다.');
    }

    function openQAPage(searchQATXT) {
        navigate('/service_center/search_help?page=otherQA&searchText='+searchQATXT);
    }

    function inquiry(inquiryPage) {
        if(localStorage.getItem('token')){
            navigate(`/service_center/inquiry?page=${inquiryPage}`);
        } else {
            navigate('/login', {state:{redirect: `/service_center/inquiry?page=${inquiryPage}`}});
        }
    }


    useEffect(() => {
        $('.subMaintitleBox').css("margin-bottom", '0');
        $('.ServiceGameCenterWarp').css('height', 'auto');
        setHeaderMainPage(false);
        $('.service_center_main_category_box').first().css('display', 'block');
        $('.subMaintitleBox').css('display', 'block');

        if (page === 'sm_dualgo' || page === 'sm_baduki' || page === 'sm_poker') {
            setShowQA(true);
            setQAPage(<QAPage gameName={page} searchTxt={''}/>);
        }
        else if (page === 'otherQA') {
            const searchText = params.get('searchText');
            setShowQA(true);
            setQAPage(<QAPage gameName={'other'} searchTxt={searchText}/>);
        }
        else if (page === 'qaHome') {
            setShowQA(true);
            setQAPage(<QAPage gameName={'other'} searchTxt={searchText} searchDiv={selectedValue}/>);
        }
        else if (page === 'headerMainPage') {
            setQAPage('');
            setShowQA(false);
            setHeaderMainPage(true);

            $('.GameCenterwarp_inner').css('height', '940px');
            $('#search_help').removeClass('active');
            $('.service_center_main_category_box').first().css('display', 'none');
            $('.subMaintitleBox').css('display', 'none');
        }
        else {
            setQAPage('');
            setShowQA(false);
        }

    }, [page]);

    return (
        <>
            <ServiceCenterHeader category="search_help"/>

            <div className="ServiceCenterWarpMain">
                <div className="ServiceGameCenterWarp">
                    <div className="GameCenterwarp_inner">

                        {headerMainPage && (
                            <>
                                <div className="search_center_warp_box3" style={{borderRight: 'none'}}>
                                    <div className="search_box3_contents_box">
                                        <div className="search_box3_title">
                                            로그인정보
                                        </div>
                                        <br/>
                                        <div className="search_box3_text">
                                            {localStorage.getItem('token') && (
                                                <>
                                                    <div
                                                        onClick={() => navigate('/my_page')}>내정보보기 &gt;</div>

                                                    <div>&nbsp;</div>
                                                </>
                                            )}

                                            {!localStorage.getItem('token') && (
                                                <>
                                                    <div
                                                        onClick={() => navigate('/login?page=serviceCenterFindID')}>아이디
                                                        찾기 &gt;</div>

                                                    <div
                                                        onClick={() => navigate('/login?page=changePassword')}>비밀번호
                                                        찾기 &gt;</div>
                                                </>
                                            )}

                                            <br/>
                                        </div>
                                        <div className="search_box3_img">
                                            <img loading="lazy"
                                                 src={require("../images/servicecenter_img/servicecenter_01.jpg")}
                                                 alt="Service Center"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="search_center_warp_box3" style={{borderRight: 'none'}}>
                                    <div className="search_box3_contents_box">
                                        <div className="search_box3_title">
                                            신고하기
                                        </div>
                                        <br/>
                                        <div className="search_box3_text">
                                            <div onClick={event => inquiry('receipt')}>게임머니 유실 신고 &gt;</div>

                                            <div onClick={event => inquiry('receipt')}>명의도용 신고 &gt;</div>

                                            <div onClick={event => inquiry('receipt')}>결제정보 도용 신고 &gt;</div>
                                        </div>
                                        <div className="search_box3_img">
                                            <img loading="lazy"
                                                 src={require("../images/servicecenter_img/servicecenter_02.jpg")}
                                                 alt="Service Center"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="search_center_warp_box3" style={{borderRight: 'none'}}>
                                    <div className="search_box3_contents_box">
                                        <div className="search_box3_title">
                                            신고내역
                                        </div>
                                        <br/>
                                        <div className="search_box3_text">
                                            <div onClick={event => inquiry('statement')}>신고내역확인 &gt;</div>
                                            <br/>
                                            <br/>
                                        </div>
                                        <div className="search_box3_img">
                                            <img loading="lazy"
                                                 src={require("../images/servicecenter_img/servicecenter_03.jpg")}
                                                 alt="Service Center"/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="subMaintitleBox">
                            <div className="subMainGrayBox"/>
                            <div className="subMaintitle">도움말 검색</div>

                            <div className="subMainDepth">고객센터 > 도움말 검색</div>
                        </div>

                        <div className="qa_box">
                            <div className="qa_title">
                                <em>도움말 검색</em>
                            </div>
                            <div className="qa_search_box">
                                <div className="search_box_drop_box">
                                    <select name="SerchDiv" className="drop_box_select" value={selectedValue}
                                            onChange={handleValueChange}>
                                        <option value="">전체</option>
                                        <option value="1">회원정보</option>
                                        <option value="2">유료/결제</option>
                                        <option value="3">신고/이용제한</option>
                                        <option value="5">서비스</option>
                                        <option value="6">게임문의</option>
                                    </select>
                                    <div className="qa_input_box">
                                        <input
                                            type="text"
                                            id="SerchText2"
                                            name="Serch"
                                            placeholder="검색어를 입력하세요"
                                            autoComplete="off"
                                            value={searchText}
                                            onChange={handleSearchTextChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="qa_search_img" onClick={handleSearchClick}>
                                <div className="qa_search_img_src"/>
                            </div>
                        </div>


                        {!showQA && (<div className="service_center_main_category_box" style={{height: '223px'}}>
                            <div className="service_category_box_title">
                                <span style={{color: '#15a3e1'}}>게임별</span> 도움말
                            </div>
                            <div className="service_category_box_subtitle">
                                <div className="service_subtitle_text_box" style={{marginRight: '17px'}}>
                                    <div className="service_subtitle_text_box_2">고스톱</div>
                                    <br/>
                                    <div className="service_sub_title_text" onClick={() => navigate('/service_center/search_help?page=sm_dualgo')}>신맞고</div>
                                    <div className="service_sub_title_text" onClick={ReadyPopup}>섯다</div>
                                    <br/><br/>
                                </div>

                                <div className="service_subtitle_text_box" style={{marginRight: '17px'}}>
                                    <div className="service_subtitle_text_box_2">포커</div>
                                    <br/>
                                    <div className="service_sub_title_text" onClick={() => navigate('/service_center/search_help?page=sm_poker')}>SM 7포커</div>
                                    <div className="service_sub_title_text" onClick={() => navigate('/service_center/search_help?page=sm_baduki')}>바둑이</div>
                                    <br/><br/>
                                </div>

                                <div className="service_subtitle_text_box" style={{marginRight: '17px'}}>
                                    <div className="service_subtitle_text_box_2">보드 캐주얼</div>
                                    <br/>
                                    <div className="service_sub_title_text" onClick={ReadyPopup}>바둑</div>
                                    <div className="service_sub_title_text" onClick={ReadyPopup}>장기</div>
                                    <div className="service_sub_title_text" onClick={ReadyPopup}>MATCHING</div>
                                    <br/>
                                </div>

                                <div className="service_subtitle_text_box" style={{marginRight: '17px'}}>
                                    <div className="service_subtitle_text_box_2">FPS</div>
                                    <br/>
                                    <div className="service_sub_title_text" onClick={ReadyPopup}>BATTLE SNIPER</div>
                                    <br/><br/><br/>
                                </div>

                                <div className="service_subtitle_text_box" style={{marginRight: '0px'}}>
                                    <div className="service_subtitle_text_box_2">RPG</div>
                                    <br/>
                                    <div className="service_sub_title_text" onClick={ReadyPopup}>DARK WORLD</div>
                                    <br/><br/><br/>
                                </div>
                            </div>
                        </div>)}


                        {!showQA && (<div className="service_center_main_category_box" style={{height: '223px'}}>
                            <div className="service_category_box_title">
                                <span style={{color: '#15a3e1'}}>서비스별</span> 도움말
                            </div>
                            <div className="service_category_box_subtitle">

                                <div className="service_subtitle_text_box" style={{marginRight: '64px'}}>
                                    <div className="service_subtitle_text_box_2">내정보</div>
                                    <br/>
                                    <div className="service_sub_title_text" onClick={event => openQAPage('분실')}>비밀번호 분실</div>
                                    <div className="service_sub_title_text" onClick={event => openQAPage( '탈퇴')}>회원탈퇴</div>
                                </div>

                                <div className="service_subtitle_text_box" style={{marginRight: '64px'}}>
                                    <div className="service_subtitle_text_box_2">결제</div>
                                    <br/>
                                    <div className="service_sub_title_text" onClick={event => openQAPage('결제')}>상품결제</div>
                                    <br/>
                                </div>

                                <div className="service_subtitle_text_box" style={{marginRight: '64px'}}>
                                    <div className="service_subtitle_text_box_2">신고/이용제한</div>
                                    <br/>
                                    <div className="service_sub_title_text" onClick={event => inquiry('main')}>명의/결제정보 도용신고</div>
                                    <div className="service_sub_title_text" onClick={event => inquiry('main')}>아이디 이용제한 해제요청</div>
                                </div>

                            </div>
                        </div>)}

                        {qaPage}
                    </div>

                    <RightGamesWithoutLogin/>
                </div>
            </div>

            <SubFooter/>

        </>

    );
}

export default SearchForHelp