import "../css/shop.css";
import React, {useEffect, useState} from "react";
import icon_arrow_top from "../images/Icon-arrow-top.png";
import icon_arrow_bottom from "../images/Icon-arrow-bottom.png";
import icon_button from "../images/mypage_img/Icon-button.png";
import $ from 'jquery';
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import axios from "axios";
import baseUrl from "../BaseUrl";
import MobilePaymentRequest from "../payment/mobilePaymentRequest";
import {useNavigate} from "react-router-dom";
import popup_icon from "../images/dualgo_ingame_img/ingame_popupNO.png";


function numberToKorean(number) {
    var avatarQuantity = number < 0 ? false : number;
    var unitWords = ['', '만', '억', '조', '경'];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = '';

    for (var i = 0; i < splitCount; i++) {
        var unitResult = (avatarQuantity % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0) {
            resultArray[i] = unitResult;
        }
    }

    for (var i = 0; i < resultArray.length; i++) {
        if (!resultArray[i]) continue;
        resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
}

function MobileBuyAvatarWeb(props) {
    const navigate = useNavigate();
    const [reward, setReward] = useState('');
    const [avatarQuantity, setAvatarQuantity] = useState(1);
    const [paymentData, setPaymentData] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [monthPayment, setMonthPayment] = useState(0);
    const [monthAvailableAmount, setMonthAvailableAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState('10,000');



    const avatarId = localStorage.getItem('avatarId');
    const avatarPrice = localStorage.getItem('avatarPrice');
    const avatarName = localStorage.getItem('avatarName');

    const handleInputChange = (event) => {
        let inputValue = event.target.value;

        inputValue = inputValue.replace(/[^0-9\s]/g, '');
        if (inputValue) {
            const newValue = Math.min(Math.max(parseInt(inputValue || 0), 1), 70);
            setAvatarQuantity(newValue);

            if (avatarPrice * (newValue) <= monthAvailableAmount) {
                var amount = new Intl.NumberFormat().format(avatarPrice * newValue);
                setTotalAmount(amount);
                setAvatarQuantity(newValue);
            } else {
                setAvatarQuantity(1);
                $("#messagePopup").css('display', 'block');
            }

        } else {
            setAvatarQuantity(inputValue);
        }

    };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleSubmit = async () => {
        const data = {
            avatarId: avatarId,
            price: avatarPrice,
            // price: 1,
            avatarName: avatarName,
            goPayMethod: paymentMethod,
            avatarQuantity: avatarQuantity,
            inGame: false,
            isMobile: true
        };

        try {
            const response = await axios.post(baseUrl + 'pay/payment_request/', data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
            setPaymentData(response.data);
            localStorage.setItem('avatarCount', avatarQuantity);

        } catch (error) {
            console.error('Error fetching payment data:', error);
        }
    };

    function numberCount(status) {
        if (status === 'up') {
            if (avatarQuantity < 70) {
                if (avatarPrice * (avatarQuantity + 1) <= monthAvailableAmount) {
                    var amount = new Intl.NumberFormat().format(avatarPrice * (avatarQuantity + 1));
                    setTotalAmount(amount);
                    setAvatarQuantity(avatarQuantity + 1);
                } else {
                    $("#messagePopup").css('display', 'block');
                }
            }
        } else {
            if (avatarQuantity >= 2) {
                setAvatarQuantity(avatarQuantity - 1);
            }
        }
    }

    const getMonthPayment = async () => {
        try {
            const response = await axios.get(baseUrl + 'shop/month_payment/', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            setMonthPayment(response.data.month_purchase_amount);
            setMonthAvailableAmount(response.data.available_purchase_amount);


        } catch (error) {
            console.error('Error fetching payment data:', error);
        }
    };

    const closePopup = () =>{
        $("#messagePopup").css('display', 'none');
    };


    useEffect(() => {
        getMonthPayment();

        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });

        const reward = localStorage.getItem('reward');
        setReward(reward);

    }, []);

    return (
        <>
            <MobileHeader/>
            <div style={{background: '#f5f5f5'}}>

                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>아바타 구매</span>
                </div>

                <div className="ShopGameCenterwarpMain">
                    <div className="m_buy_shop_center_wrap">
                        <div className="m_buy_section">
                            <div className="m_buy_contents">
                                <div className="m_buy_avatar_img">
                                    <img src={require('../images/shop_img/' + avatarId + '.gif')} alt="Avatar"/>
                                </div>
                                <div className="m_buy_contents_inner">
                                    <div className="m_buy_title_box" style={{borderBottom: '2px solid #4e4e4e'}}>
                                        {avatarName}
                                    </div>
                                    <div className="m_buy_contents_box">
                                        <div className="m_buy_contents_title_box">수량</div>
                                        <div className="m_buy_contents_text_box">
                                            <div className="m_count_number">
                                                <input type="text" id="numberUpDown" value={avatarQuantity}
                                                       onChange={handleInputChange} autoComplete="off"/>
                                            </div>
                                            <div className="m_count_number_up_down_box">
                                                <div onClick={event => numberCount('up')}>
                                                    <img className="m_count_number_up_img" src={icon_arrow_top}
                                                         alt="Increase"/>
                                                </div>
                                                <div onClick={event => numberCount('down')}>
                                                    <img className="m_countnumber_down_img" src={icon_arrow_bottom}
                                                         alt="Decrease"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="m_buy_contents_box">
                                        <div className="m_buy_contents_title_box">게임머니</div>
                                        <div className="m_buy_contents_text_box">
                                            <div style={{marginTop: '2vw'}}>
                                                <em id="GetGold">{numberToKorean(reward * avatarQuantity)} 골드(냥) 지급</em>
                                            </div>
                                            <input type="hidden" id="PHPreward" value={reward}/>
                                        </div>
                                    </div>
                                    <div className="m_buy_contents_box">
                                        <div className="m_buy_contents_title_box">결제금액</div>
                                        <div className="m_buy_contents_text_box">
                                            <div id="BuyPayment"
                                                 style={{marginTop: '2vw'}}>{new Intl.NumberFormat().format(avatarPrice * avatarQuantity)}원
                                            </div>
                                            <input type="hidden" id="PHPBuyPayment"
                                                   value={avatarPrice * avatarQuantity}/>
                                        </div>
                                    </div>

                                    <div className="m_buy_contents_box">
                                        <div className="m_buy_contents_title_box">결제방법</div>
                                        <div style={{
                                            height: '6.2500vw',
                                            position: 'absolute',
                                            display: 'inline-block',
                                            backgroundColor: '#ededed',
                                            marginTop: '0.7813vw'
                                        }}>
                                            <select id="gopaymethod" name="gopaymethod"
                                                    className="m_buydrop_box_select" value={paymentMethod}
                                                    onChange={handlePaymentMethodChange}>
                                                <option value="HPP" style={{background: '#ededed'}}>모바일결제</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="m_buy_money_text_box">
                                        <div className="m_buy_money_text_box_inner"
                                             style={{borderBottom: '0.1563vw solid #dbdbdb'}}>
                                            <div style={{
                                                position: 'absolute',
                                                display: 'inline-block',
                                                marginLeft: '3.1250vw'
                                            }}>
                                                이번달 구매 총 금액
                                            </div>
                                            <div style={{
                                                width: '17.1875vw',
                                                display: 'inline-block',
                                                textAlign: 'right',
                                                marginLeft: '26vw'
                                            }}>
                                                {monthPayment && (monthPayment.toLocaleString())}<em>원</em>
                                            </div>
                                            <div className="m_buy_money_text_box_inner" style={{marginTop: '0.5vw'}}>
                                                <div style={{
                                                    position: 'absolute',
                                                    display: 'inline-block',
                                                    marginLeft: '3.1250vw'
                                                }}>
                                                    추가 구매 가능 금액
                                                </div>
                                                <div style={{
                                                    width: '17.1875vw',
                                                    display: 'inline-block',
                                                    textAlign: 'right',
                                                    marginLeft: '26vw'
                                                }}>
                                                    {monthAvailableAmount && (monthAvailableAmount.toLocaleString())}<em>원</em>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="m_buy_total_money_box">
                                        총 상품금액 : <em id="BuyPaymentTotal"
                                                     style={{fontSize: '3.1250vw', color: '#15a3e1'}}>{totalAmount}</em><em
                                        style={{fontSize: '3.1250vw', color: '#15a3e1'}}>원</em>
                                    </div>

                                    <div style={{fontSize: '2vw', textAlign: 'center'}}>내지갑에서 사용한 아바타는 환불이 불가능합니다.</div>


                                    <div className="quit_oknoBox_button">
                                        <button className="m_quit_ok_no_button" type="button" onClick={handleSubmit}>
                                            구매하기
                                            <div className="m_quit_ok_no_button_img">
                                                <img src={icon_button} alt="구매하기"/>
                                            </div>
                                        </button>
                                        <button className="m_quit_ok_no_button" type="button" onClick={() => {
                                            navigate('/mobile/shop')
                                        }} style={{backgroundColor: '#4e4e4e'}}>
                                            취소
                                            <div className="m_quit_ok_no_button_img">
                                                <img src={icon_button} alt="취소"/>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {paymentData && <MobilePaymentRequest paymentData={paymentData}/>}

            <MobileFooter/>

            <div id="messagePopup" className="m_shop_popup">
                <div className="m_my_wallet_popup_content">
                    <div className="message-popup-content" style={{marginTop: '0'}}>
                        <img src={popup_icon} className="animated shake" alt="OK"/>
                        <span style={{fontSize: '4vw', marginTop: '6vw'}}>추가 구매 가능 금액을 초과하였습니다.</span>
                    </div>
                    <div onClick={closePopup} className="message-pass-btn-web">
                        확 인
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileBuyAvatarWeb;