import React, {useEffect} from "react";
import MobileHeader from "./mHeader";
import MobileFooter from "./mFooter";
import {useNavigate} from "react-router-dom";
import $ from "jquery";

const MobileAllGames = () => {
    const navigate = useNavigate();

    function openGamePage(gameName) {
        window.location.href = `/mobile/upcoming_games/?gameName=${gameName}`;
    }

    function openMainGamePage(gameName) {
        localStorage.setItem('gameName', gameName);
        if(gameName === 'sm_dualgo') {
            window.location.href = "https://play.google.com/store/apps/details?id=kr.co.smgame.dualgo1";
            return;
        }

        if(localStorage.getItem('token'))
            window.location.href = `/game?gameName=${gameName}`;
        else
            navigate('/mobile/login', {state:{redirect: `/game?gameName=${gameName}`}});
    }

    useEffect(() => {
        $('body').css({
            'min-width': '320px',
            'transform': 'unset',
            'zoom': 'unset'
        });
    }, []);

    return (
        <>
            <div style={{background: '#f5f5f5'}}>
                <MobileHeader/>

                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>전체게임</span>
                </div>


                <div className="m_all_game_content">
                    <div className="m_all_game_box" style={{borderTop: '1px solid #4e4e4e'}}>
                        <div className="m_all_game_box_title">
                            <div className="m_all_game_inner">
                                고스톱
                            </div>
                            <div className="m_all_game_title_img">
                                <img src={require("../images/mobile_img/mobile_A01.png")}/>
                            </div>
                        </div>

                        <div className="m_all_game_content_box">
                            <div className="m_all_game_inner">
                                <div className="m_all_game_content_txt"
                                     onClick={() => openMainGamePage('sm_dualgo')}>신맞고
                                </div>
                                <div className="m_all_game_content_txt"
                                     onClick={event => openGamePage('game_gostop')}>고스톱
                                </div>
                                <div className="m_all_game_content_txt" onClick={event => openGamePage('game_sudda')}>섯다
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="m_all_game_box">
                        <div className="m_all_game_box_title">
                            <div className="m_all_game_inner">
                                포커
                            </div>
                            <div className="m_all_game_title_img">
                                <img src={require("../images/mobile_img/mobile_A02.png")}/>
                            </div>
                        </div>

                        <div className="m_all_game_content_box">
                            <div className="m_all_game_inner">
                                <div className="m_all_game_content_txt"
                                     onClick={() => openMainGamePage('sm_baduki')}>바둑이
                                </div>
                                <div className="m_all_game_content_txt" onClick={() => openMainGamePage('sm_poker')}>7포커
                                </div>
                                <div className="m_all_game_content_txt"
                                     onClick={() => openMainGamePage('sm_smpl')}>SMPL
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="m_all_game_box">
                        <div className="m_all_game_box_title">
                            <div className="m_all_game_inner">
                                RPG
                            </div>
                            <div className="m_all_game_title_img">
                                <img src={require("../images/mobile_img/mobile_A03.png")}/>
                            </div>
                        </div>

                        <div className="m_all_game_content_box">
                            <div className="m_all_game_inner">
                                <div className="m_all_game_content_txt"
                                     onClick={event => openGamePage('game_darkworld')}>DARK WORLD
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="m_all_game_box">
                        <div className="m_all_game_box_title">
                            <div className="m_all_game_inner">
                                FPS/스포츠
                            </div>
                            <div className="m_all_game_title_img">
                                <img src={require("../images/mobile_img/mobile_A04.png")}/>
                            </div>
                        </div>

                        <div className="m_all_game_content_box">
                            <div className="m_all_game_inner">
                                <div className="m_all_game_content_txt"
                                     onClick={event => openGamePage('game_battlesniper')}>BATTLE SNIPER
                                </div>
                                <div className="m_all_game_content_txt"
                                     onClick={event => openGamePage('game_probaseball')}>프로 베이스볼
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="m_all_game_box">
                        <div className="m_all_game_box_title">
                            <div className="m_all_game_inner">
                                보드/캐주얼
                            </div>
                            <div className="m_all_game_title_img">
                                <img src={require("../images/mobile_img/mobile_A05.png")}/>
                            </div>
                        </div>

                        <div className="m_all_game_content_box">
                            <div className="m_all_game_inner">
                                <div className="m_all_game_content_txt"
                                     onClick={event => openGamePage('game_baduk')}>바둑
                                </div>
                                <div className="m_all_game_content_txt"
                                     onClick={event => openGamePage('game_janggi')}>장기
                                </div>
                                <div className="m_all_game_content_txt"
                                     onClick={event => openGamePage('game_matching')}>SM MATCHING
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <MobileFooter/>
        </>
    );
}

export default MobileAllGames;