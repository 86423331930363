import '../css/main.css';
import 'swiper/swiper-bundle.css';
import Slider from "./Slider";
import go_stop from "../file/Gostop.png";
import baduki from "../file/Baduki icon.png";
import poker from "../file/7 Poker icon.png";
import smpl from "../file/SMPL Icon.png";
import main_games_icon from "../file/main_game_Icon.svg";
import GameBanner from "./gameBanner";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import Rectangle from "../images/main_img/Rectangle 12.png";

function Home() {
    const navigate = useNavigate();

    const openGamePage = async (gameName) => {
        if(localStorage.getItem('token')) {
            navigate(`/game?gameName=${gameName}`);
        } else {
            navigate('/login', {state: {redirect: `/game?gameName=${gameName}`}});
        }
    };

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
        return null;
    }


    const verifyToken = async () => {
        const token = getCookie('token');
        const tokenTime = getCookie('tokenTime');

        if(token && tokenTime) {
            localStorage.setItem('token', token);
            localStorage.setItem('token', token);
        }
        else {
            localStorage.clear();
            sessionStorage.clear();
        }
    };

    useEffect(() => {
        verifyToken();

        const [navigationEntry] = performance.getEntriesByType('navigation');
        if (navigationEntry.type === "back_forward") {
            console.log("pass");
            window.scrollTo(0, parseInt(sessionStorage.getItem('scrollPosition'), 10));
        } else {
            sessionStorage.setItem('scrollPosition', window.scrollY);
        }

        console.log(navigationEntry.type);

    }, []);


    return (
        <>
            <Slider/>

            <div className="main_game_tab_txt_div">
                <div style={{display: "block"}}>
                    <div className="main_game_tab_text">
                        <img style={{width: '30px', height: '30px',marginTop: '5px', paddingRight: '10px'}} src={main_games_icon}/>
                        주요게임
                    </div>
                    <img style={{width: '77px', height: '3px', paddingLeft: '40px'}} loading="lazy" src={Rectangle} alt="Coming Soon"/>
                </div>
            </div>

            <div style={{display: 'flex'}}>
            <div className="main_div_carosel" style={{height: 'auto', alignItems: 'start', paddingBottom: '5.3rem'}}>
                    <div className="main_div_carosel2">

                        <div id="go_stop_box" className="BoxClass GoStopBoxClass"
                             onClick={event => openGamePage("sm_dualgo")}>
                            <div className="main_div_images">
                                <img src={go_stop} alt=""/>
                            </div>
                        </div>


                        <div id="baduki_box" className="BoxClass BadukiBoxClass"
                             onClick={event => openGamePage("sm_baduki")}>
                            <div className="main_div_images">
                                <img src={baduki} alt=""/>
                            </div>
                        </div>


                        <div id="7_poker_box" className="BoxClass PokerBoxClass"
                             onClick={event => openGamePage("sm_poker")}>
                            <div className="main_div_images">
                                <img src={poker} alt=""/>
                            </div>
                        </div>

                        <div id="smpl_box" className="BoxClass SmplBoxClass" onClick={event => openGamePage("sm_smpl")}>
                            <div className="main_div_images">
                                <img src={smpl} alt=""/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <GameBanner/>

        </>
    );
}

export default Home;