import React, {useEffect, useState} from 'react';
import "../css/serviceCenter.css";
import "../css/myPage.css";
import axios from "axios";
import baseUrl from "../BaseUrl";


const BasicInformation = () => {
    const [userInfo, setUserInfo] = useState([]);

    const basicInfoData = async () => {
        try {
            const response = await axios.get(baseUrl + 'my_page/basic_info/', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
            if (response.status === 200) {
                setUserInfo(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        basicInfoData();
    }, []);


    return (
        <>
            <div className="subMaintitleBox">
                <div className="subMainGrayBox" />
                <div className="subMaintitle">기본정보</div>

                <div className="subMainDepth">내정보 > 기본정보</div>
            </div>

            <div className="my_page_contents_box">
                {userInfo.map((user) => (
                    <div key={user.id} className="my_page_contents">
                        <div className="my_contents_inner_left_box">
                            <em>{user.name}</em>
                        </div>
                        <div className="my_contents_inner_right_box">
                            <em>{user.value ? user.value : <>&nbsp;&nbsp;&nbsp;</>}</em>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default BasicInformation;
