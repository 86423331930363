import {useEffect} from 'react';


const GameUrl = () => {

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        localStorage.setItem('token', params.get('token'));
        const gameName =  params.get('gameName');
        window.location.href = '/game/?gameName='+ gameName;
    }, []);

    return ;
};

export default GameUrl;
