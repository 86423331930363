import React, {useEffect, useState} from 'react';
import '../css/webPopup.css';
import $ from "jquery";
import star_icon from "../images/dualgo_ingame_img/Star_Icon_or.svg";
import star_icon_black from "../images/dualgo_ingame_img/Star_Icon.svg";
import axios from "axios";
import baseUrl from "../BaseUrl";
import ToKoreanNotation from "../popups/popupComman";
import MessagePopup, {CheckPlatform} from "./messagePopup";
import CashBox from '../css/inGamePopup.module.css';
import gold_icon from "../images/dualgo_ingame_img/ingame_activeGO.png";
import egg_icon from "../images/dualgo_ingame_img/ingame_activeAL.png";



const InGameFreeCharge = () => {
    const [currency, setCurrency] = useState('go');
    const [unitDisplayGO, setUnitDisplayGO] = useState('');
    const [currencyUnit, setCurrencyUnit] = useState('');
    const [moneyData, setMoneyData] = useState({go: {}, al: {}});
    const [messagePopup, setMessagePopup] = useState(false);
    const [status, setStatus] = useState(false);
    const [platformMessage, setPlatformMessage] = useState('');
    const [showCloseBtn, setShowCloseBtn] = useState(false);


    const params = new URLSearchParams(window.location.search);
    const gameName = params.get('gameName');
    const token = params.get('token');
    

    const changeCurrency = (currency_type) => {
        setCurrency(currency_type);

        if (currency_type === 'go') {
            setCurrencyUnit(unitDisplayGO);
        } else {
            setCurrencyUnit('알');
        }

    };

    const fetchMoneyData = async () => {
        try {
            const response = await axios.get(baseUrl + 'popups/free_charge/', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200){
                setMoneyData(response.data);
            }

        } catch (e) {
            alert("Invalid token");
        }
    };

    async function handleOnSubmit() {
        if (!moneyData[currency].free_charge_possible) {
            setStatus(false);
            setMessagePopup(moneyData[currency].free_charge_title);
            $('.message-main-pop').css("display", "block");
            return ;
        }

        const data = {
            currency: currency,
        };


        try {
            const response = await axios.post(baseUrl + 'popups/update_free_charge/', data, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (response.status === 200) {
                setStatus(true);
                setMessagePopup("무료충전이 완료되었습니다.");
                $('.message-main-pop').css("display", "block");
                setMoneyData(response.data);
            } else if(response.status === 204) {
                setStatus(false);
                setMessagePopup("게임 진행 중에는 금고 입•출금이 제한 됩니다.");
                $('.message-main-pop').css("display", "block");
            }

        } catch (e) {
            console.log(e);
            setStatus(false);
            setMessagePopup("금액 초과 무료충전이 제한됩니다.");
            $('.message-main-pop').css("display", "block");
        }


    }


    useEffect(() => {
        $('body').css({
            'transform': 'unset',
            'min-width': 'unset',
            'zoom': 'unset',
        });

        if (!gameName) {
            return alert("gameName is missing in url parameter");
        } else if (!token){
            return alert("token is missing in url parameter");
        }

        fetchMoneyData();

        if (gameName === "sm_dualgo") {
            setUnitDisplayGO("냥");
            setCurrencyUnit("냥");
        } else {
            setUnitDisplayGO("골드");
            setCurrencyUnit("골드");
        }

        var checkPlatform = CheckPlatform();
        setPlatformMessage(checkPlatform[0]);
        setShowCloseBtn(checkPlatform[1]);
        
    }, [gameName]);


    return (
        <>
            {messagePopup && (
                <MessagePopup message={messagePopup} status={status} closeMessagePopup={setMessagePopup}/>)}

            <div className={CashBox.in_game_popup_background}>
                {showCloseBtn && (<div className={CashBox.in_game_close_btn} onClick={() => window.self.close()}/>)}


                <div>
                    <div className={CashBox.cash_box_currency_switch}>
                        <div
                            onClick={() => changeCurrency('go')}
                            className={`${CashBox.currency_option} ${currency === 'go' ? CashBox.currency_option_active : CashBox.currency_option_not_active}`}
                        >

                            <img src={gold_icon} alt="currency-symbol-1" style={{width: '2vw', height: '2vw'}}/>
                            <p style={{
                                color: currency === 'go' ? '#fff' : '#e4831b',
                                fontSize: '1.6vw',
                                fontWeight: 700
                            }}>{unitDisplayGO}</p>
                        </div>
                        <div
                            onClick={() => changeCurrency('al')}
                            className={`${CashBox.currency_option} ${currency === 'al' ? CashBox.currency_option_active : CashBox.currency_option_not_active}`}
                        >

                            <img src={egg_icon} alt="currency-symbol-2" style={{width: '1.8vw', height: '1.8vw'}}/>
                            <p style={{
                                color: currency === 'al' ? '#fff' : '#e4831b',
                                fontSize: '1.6vw',
                                fontWeight: 700
                            }}>알</p>
                        </div>
                    </div>

                    <div className={CashBox.cash_box_tab_content} style={{marginTop: '4vw'}}>
                        <div className={CashBox.cash_box_current_tab_div}>
                            {/* List 1 */}
                            <div className={CashBox.cash_box_money_box_div}>
                                <div className={CashBox.cash_box_label_box}>
                                    보유머니
                                </div>
                                <div className={CashBox.cash_box_money} style={{backgroundColor: 'unset'}}>
                                    <div>{ToKoreanNotation(moneyData[currency].total_money, currencyUnit)}</div>
                                </div>
                            </div>

                        </div>


                        <div className={CashBox.free_charge_container_div}>
                            <div>
                                <div className={CashBox.free_charge_container}>
                                    <img src={star_icon} alt=""/>
                                    <p style={{marginLeft: '0.5vw'}}>
                                        {moneyData[currency].free_charge_title}
                                    </p>
                                </div>

                                <div className={CashBox.free_charge_container}>
                                    <img src={star_icon_black} alt=""/>
                                    <p style={{marginLeft: '0.5vw', color: '#414141'}}>
                                        총 보유머니가 {moneyData[currency].free_charge_amount}{currencyUnit} 미만일 때 무료충전이
                                        가능합니다.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className={CashBox.in_game_popup_btn_container} style={{marginTop: '5vw'}}>
                            <div
                                className={CashBox.in_game_popup_btn}
                                onClick={() => handleOnSubmit()}
                            >
                                <div className={CashBox.in_game_popup_btn_title}>
                                    확인
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="day_limit_bottom_txt" style={{marginTop: "27vh"}}>
                    {platformMessage}
                </div>

            </div>

        </>

    );
};

export default InGameFreeCharge;
