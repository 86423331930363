import React, {useEffect} from 'react';
import icon_won from '../images/Icon-won.png';

import {getAvatars} from "./avatar";
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import popup_img from "../images/dualgo_ingame_img/ingame_popupNO.png";

function ShopAvatar({tokenVerified}) {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    var page = params.get('page');
    var redirect = params.get('redirect');

    var gender = localStorage.getItem('gender');
    var shopAvatars;

    if (!gender && localStorage.getItem('token')){
        gender = 'y';
    }

    if (page === "smpl_avatars") {
        $('#smpl_avatars').css('color', '#15a3e1');
        shopAvatars = getAvatars(page, gender);
    } else {
        page = "main_avatars";
        $('#main_avatars').css('color', '#15a3e1');
        shopAvatars = getAvatars(page, gender);
    }

    function numberToKorean(number) {
        var inputNumber = number < 0 ? false : number;
        var unitWords = ['', '만', '억', '조', '경'];
        var splitUnit = 10000;
        var splitCount = unitWords.length;
        var resultArray = [];
        var resultString = '';

        for (var i = 0; i < splitCount; i++) {
            var unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
            unitResult = Math.floor(unitResult);
            if (unitResult > 0) {
                resultArray[i] = unitResult;
            }
        }

        for (var k = 0; k < resultArray.length; k++) {
            if (!resultArray[k]) continue;
            resultString = String(resultArray[k]) + unitWords[k] + resultString;
        }

        return resultString;
    }

    function numberFormat(number) {
        return Intl.NumberFormat().format(number);
    }

    function buyAvatar (avatarId, reward, price, avatarName) {
        window.scroll(0,0);
        localStorage.setItem('avatarId', avatarId);
        localStorage.setItem('reward', reward);
        localStorage.setItem('avatarPrice', price);
        localStorage.setItem('avatarName', avatarName);

        if (tokenVerified){
            localStorage.setItem('avatarId', avatarId);
            localStorage.setItem('reward', reward);
            localStorage.setItem('avatarPrice', price);
            localStorage.setItem('avatarName', avatarName);
            localStorage.setItem('cancel_avatar_page', page);
            navigate('/buy_avatar_web');
        } else {
            navigate('/login', {state:{redirect: `/shop/?page=${page}&redirect=true`}});
        }
    }

    const closePopup = () =>{
        $("#messagePopup").css('display', 'none');
        navigate('/shop');

    };

    useEffect(() => {
        if (redirect && gender === 'n'){
            $("#messagePopup").css('display', 'block');
        }
    }, []);

    return (
        <>
            <div className="shop_section_2">
                <div className="tab_box">
                    <div className="tabA">
                        <button className="tablinksA active" onClick="openTabA(event, 'tabA2')">남성아바타</button>
                    </div>

                    <div id="tab2" className="shop-tab-content">
                        {shopAvatars.map((item, index) => {
                            const avatarId = item[0];
                            const avatarName = item[1];
                            const price = item[2];
                            const reward = item[3];

                            return (
                                <div key={index} className="shop_card_item shop_col_type1">
                                    <div className="card card_square">
                                    <span className="card_img_box_game"
                                          onClick={() => buyAvatar(avatarId, reward, price, avatarName)}>
                                        <img loading="lazy" src={require('../images/shop_img/' + avatarId + '.gif')}
                                             alt=""/>
                                    </span>
                                        <em className="shop_card_tit">{avatarName}</em>
                                        <span className="shop_card_txt">({numberToKorean(reward)}골드 지급)</span>
                                        <div className="shop_card_price">
                                            <img loading="lazy" src={icon_won} style={{padding: '6px 3px'}} alt=""/>
                                            <span>{numberFormat(price)}원</span>
                                        </div>
                                        <div onClick={event => buyAvatar(avatarId, reward, price, avatarName)}>
                                            <button className="buybutton">구매</button>
                                        </div>

                                    </div>
                                </div>
                            );

                        })}
                    </div>


                    <div className="shop_tab_center_bar" style={{marginTop: "-353px"}}></div>
                    <div className="shop_tab_center_bar" style={{marginTop: "-686px"}}></div>
                    <div className="shop_tab_center_bar" style={{marginTop: "-1019px"}}></div>
                    <div className="shop_tab_center_bar" style={{marginTop: "-1352px"}}></div>
                    <div className="shop_tab_center_bar" style={{position: 'relative', height: '10px'}}></div>

                    {!gender && (
                        <div id="tab1" className="tabcontentGame">
                            <div className="shop_card_tit"
                                 style={{width: '100%', margin: '180px auto', textAlign: 'center'}}>로그인 후 이용해주세요.
                            </div>
                        </div>
                    )}
                    <div id="tab4" className="tabcontentGame" style={{display: 'none'}}></div>
                </div>
            </div>


            <div id="messagePopup" className="shop_message_popup">
                <div className="my_wallet_popup_content">
                    <div className="message-popup-content" style={{marginTop: '0'}}>
                        <img src={popup_img} className="animated shake" alt=""/>
                        <span style={{fontSize: '16px', marginTop: '20px'}}>구매할 수 없는 상품입니다.</span>
                    </div>
                    <div onClick={closePopup} className="message-pass-btn-web">
                        확 인
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShopAvatar;
